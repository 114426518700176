import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAIL,
  SERVICES_GRAPH_REQUEST,
  SERVICES_GRAPH_SUCCESS,
  SERVICES_GRAPH_FAIL,
  USERS_GRAPH_REQUEST,
  USERS_GRAPH_SUCCESS,
  USERS_GRAPH_FAIL,
  CLEAR_ERRORS,
} from '../constants/Dashboard'

export const dashboardReducer = (
  state = { status: {}, users: {}, resellers: {} },
  action
) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        loading: true,
      }

    case DASHBOARD_SUCCESS:
      return {
        loading: false,
        status: action.payload.status,
        users: action.payload.users,
        resellers: action.payload.resellers,
      }

    case DASHBOARD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const servicesGraphReducer = (state = { requestsData: [] }, action) => {
  switch (action.type) {
    case SERVICES_GRAPH_REQUEST:
      return {
        loading: true,
      }

    case SERVICES_GRAPH_SUCCESS:
      return {
        loading: false,
        requestsData: action.payload.results,
      }

    case SERVICES_GRAPH_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const usersGraphReducer = (state = { usersData: [] }, action) => {
  switch (action.type) {
    case USERS_GRAPH_REQUEST:
      return {
        loading: true,
      }

    case USERS_GRAPH_SUCCESS:
      return {
        loading: false,
        usersData: action.payload.results,
      }

    case USERS_GRAPH_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
