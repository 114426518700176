import { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { useDispatch } from 'react-redux'
import { getProfileData } from '../../redux/actions/UserAuth'

import AdminViews from '../../views/admin-views'
import Sidenav from '../../components/layout-components/Sidenav'
import HeaderNav from '../../components/layout-components/HeaderNav'
import ModalComponent from '../../features/components/Modal'

const { Header, Sider, Content } = Layout

export function AdminLayout() {
  const dispatch = useDispatch()

  const [navOpen, setNavOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    dispatch(getProfileData('admin'))
  }, [dispatch])

  return (
    <>
      <Layout id='scroller' className='dashboard-layout'>
        <Sider className={`${navOpen ? 'nav-open' : ''}`} collapsed={collapsed}>
          <Sidenav {...{ navOpen, setNavOpen }} />
        </Sider>
        <Layout id='scroller'>
          <Header
            className='py-4 position-sticky top-0'
            style={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              zIndex: '99',
            }}
          >
            <HeaderNav {...{ navOpen, setNavOpen, collapsed, setCollapsed }} />
          </Header>
          <Content>
            <AdminViews />
            <div className='empty'></div>
          </Content>
        </Layout>
      </Layout>
      <ModalComponent />
    </>
  )
}

export default AdminLayout
