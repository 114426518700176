import {
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_RESET,
  UPDATE_NOTIFICATION_FAIL,
  CLEAR_ERRORS,
} from '../constants/Notification'

export const allNotificationsReducer = (state = { notifications: [] }, action) => {
  switch (action.type) {
    case ALL_NOTIFICATIONS_REQUEST:
      return {
        loading: true,
      }

    case ALL_NOTIFICATIONS_SUCCESS:
      return {
        loading: false,
        notifications: action.payload.notifications.data,
        currentPage: action.payload.notifications.meta.current_page,
        totalPages: action.payload.notifications.meta.last_page,
        totalItems: action.payload.notifications.meta.total,
      }

    case ALL_NOTIFICATIONS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_NOTIFICATION_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}