import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { clearModal } from '../../redux/actions/Modal'

const ModalComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen, title, description, content, width } = useSelector(
    (state) => state.modal
  )

  const onCancel = () => {
    dispatch(clearModal())
  }

  return (
    <Modal
      width={width}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <div className='text-center mt-2 mb-4'>
        <h3 className='fs-5 text-black mx-auto mb-0' style={{ width: '90%' }}>
          {t(title)}
        </h3>
        {description ? (
          <p className='fs-14 text-dimmed mt-2'>{description}</p>
        ) : null}
      </div>
      {content}
    </Modal>
  )
}

export default ModalComponent
