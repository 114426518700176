export const ALL_THANKS_REQUEST = 'ALL_THANKS_REQUEST'
export const ALL_THANKS_SUCCESS = 'ALL_THANKS_SUCCESS'
export const ALL_THANKS_FAIL = 'ALL_THANKS_FAIL'

export const CREATE_THANKS_REQUEST = 'CREATE_THANKS_REQUEST'
export const CREATE_THANKS_SUCCESS = 'CREATE_THANKS_SUCCESS'
export const CREATE_THANKS_RESET = 'CREATE_THANKS_RESET'
export const CREATE_THANKS_FAIL = 'CREATE_THANKS_FAIL'

export const UPDATE_THANKS_REQUEST = 'UPDATE_THANKS_REQUEST'
export const UPDATE_THANKS_SUCCESS = 'UPDATE_THANKS_SUCCESS'
export const UPDATE_THANKS_RESET = 'UPDATE_THANKS_RESET'
export const UPDATE_THANKS_FAIL = 'UPDATE_THANKS_FAIL'

export const DELETE_THANKS_REQUEST = 'DELETE_THANKS_REQUEST'
export const DELETE_THANKS_SUCCESS = 'DELETE_THANKS_SUCCESS'
export const DELETE_THANKS_RESET = 'DELETE_THANKS_RESET'
export const DELETE_THANKS_FAIL = 'DELETE_THANKS_FAIL'

export const THANKS_LIST_DETAILS_REQUEST = 'THANKS_LIST_DETAILS_REQUEST'
export const THANKS_LIST_DETAILS_SUCCESS = 'THANKS_LIST_DETAILS_SUCCESS'
export const THANKS_LIST_DETAILS_FAIL = 'THANKS_LIST_DETAILS_FAIL'

export const CREATE_THANKS_LIST_REQUEST = 'CREATE_THANKS_LIST_REQUEST'
export const CREATE_THANKS_LIST_SUCCESS = 'CREATE_THANKS_LIST_SUCCESS'
export const CREATE_THANKS_LIST_RESET = 'CREATE_THANKS_LIST_RESET'
export const CREATE_THANKS_LIST_FAIL = 'CREATE_THANKS_LIST_FAIL'

export const UPDATE_THANKS_LIST_REQUEST = 'UPDATE_THANKS_LIST_REQUEST'
export const UPDATE_THANKS_LIST_SUCCESS = 'UPDATE_THANKS_LIST_SUCCESS'
export const UPDATE_THANKS_LIST_RESET = 'UPDATE_THANKS_LIST_RESET'
export const UPDATE_THANKS_LIST_FAIL = 'UPDATE_THANKS_LIST_FAIL'

export const DELETE_THANKS_LIST_REQUEST = 'DELETE_THANKS_LIST_REQUEST'
export const DELETE_THANKS_LIST_SUCCESS = 'DELETE_THANKS_LIST_SUCCESS'
export const DELETE_THANKS_LIST_RESET = 'DELETE_THANKS_LIST_RESET'
export const DELETE_THANKS_LIST_FAIL = 'DELETE_THANKS_LIST_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'