import { Row, Col, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InfoCircleOutlined } from '@ant-design/icons'

import './RadioGroup.scss'

const RadioGroup = ({
  required = false,
  isFilter,
  name,
  label,
  value,
  setValue,
  error,
  options,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <div className='radio-group'>
      {label && (
        <div className='d-flex align-items-center gap-2 mb-2'>
          <label className='radio-group-label mb-0' htmlFor={name}>
            {t(label)} {required ? <span className='text-danger'>*</span> : null}
          </label>
          {error && (
            <InfoCircleOutlined
              style={{ color: '#D93F21', fontSize: '1rem' }}
            />
          )}
        </div>
      )}

      <div
        className='radio-group-input'
        style={{
          backgroundColor: isFilter ? 'rgba(221, 221, 221, 0.20)' : '#FFF',
        }}
      >
        <Radio.Group
          className='w-100'
          value={value}
          onChange={(e) => {
            if (isFilter) {
              const searchParams = new URLSearchParams(history.location.search)
              searchParams.set(name, e.target.value)
              searchParams.set('page', 1)
              history.push({
                pathname: history.location.pathname,
                search: searchParams.toString(),
              })
            } else {
              setValue(e.target.value)
            }
          }}
        >
          <Row justify='space-between'>
            {options.map((option, index) => (
              <Col key={index}>
                <Radio value={option.value}>
                  <span className='radio-option'>{t(option.label)}</span>
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </div>
    </div>
  )
}

export default RadioGroup
