import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('SMARTCAF_JWT_TOKEN')
const language = Cookies.get('i18next')

const url = process.env.REACT_APP_HOST

const axiosInstance = axios.create({
  baseURL: `${url}api`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
    lang: language,
  },
})

export const axiosFront = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
    lang: language,
  },
})

export const axiosFormData = axios.create({
  baseURL: `${url}api`,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: token,
    lang: language,
  },
})

export default axiosInstance
