export const ALL_PERMISSIONS_REQUEST = 'ALL_PERMISSIONS_REQUEST'
export const ALL_PERMISSIONS_SUCCESS = 'ALL_PERMISSIONS_SUCCESS'
export const ALL_PERMISSIONS_FAIL = 'ALL_PERMISSIONS_FAIL'

export const ALL_ROLES_REQUEST = 'ALL_ROLES_REQUEST'
export const ALL_ROLES_SUCCESS = 'ALL_ROLES_SUCCESS'
export const ALL_ROLES_FAIL = 'ALL_ROLES_FAIL'

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_RESET = 'CREATE_ROLE_RESET'
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL'

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_RESET = 'UPDATE_ROLE_RESET'
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL'

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_RESET = 'DELETE_ROLE_RESET'
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'