import {
  CREATE_FORM_QUESTION_REQUEST,
  CREATE_FORM_QUESTION_SUCCESS,
  CREATE_FORM_QUESTION_RESET,
  CREATE_FORM_QUESTION_FAIL,
  UPDATE_FORM_QUESTION_REQUEST,
  UPDATE_FORM_QUESTION_SUCCESS,
  UPDATE_FORM_QUESTION_RESET,
  UPDATE_FORM_QUESTION_FAIL,
  DELETE_FORM_QUESTION_REQUEST,
  DELETE_FORM_QUESTION_SUCCESS,
  DELETE_FORM_QUESTION_RESET,
  DELETE_FORM_QUESTION_FAIL,
  CLEAR_ERRORS,
} from '../constants/FormQuestion'

export const createFormQuestionReducer = (state = { question: null }, action) => {
  switch (action.type) {
    case CREATE_FORM_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_FORM_QUESTION_SUCCESS:
      return {
        loading: false,
        question: action.payload.question,
      }

    case CREATE_FORM_QUESTION_RESET:
      return {
        loading: false,
        question: null,
        message: null,
      }

    case CREATE_FORM_QUESTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const formQuestionReducer = (state = { form: {} }, action) => {
  switch (action.type) {
    case UPDATE_FORM_QUESTION_REQUEST:
    case DELETE_FORM_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_FORM_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        form: action.payload.form,
      }

    case UPDATE_FORM_QUESTION_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      }

    case DELETE_FORM_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_FORM_QUESTION_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_FORM_QUESTION_FAIL:
    case DELETE_FORM_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
