import axios from '../../configs/APIConfig/axiosInstance'
import {
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  CLEAR_ERRORS,
} from '../constants/Notification'

// LIST ALL NOTIFICATIONS
export const queryNotifications = (page) => async (dispatch) => {
  try {
    dispatch({ type: ALL_NOTIFICATIONS_REQUEST })

    const { data } = await axios.get(`/notifications?page=${page}`)

    dispatch({
      type: ALL_NOTIFICATIONS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ALL_NOTIFICATIONS_FAIL,
      payload: error.response,
    })
  }
}

// MARK AS SEEN
export const markAsSeen = (callback) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_NOTIFICATION_REQUEST })

    const { data } = await axios.get('/notifications/mark_as_seen')

    dispatch({
      type: UPDATE_NOTIFICATION_SUCCESS,
      payload: data,
    })
    callback()
  } catch (error) {
    dispatch({
      type: UPDATE_NOTIFICATION_FAIL,
      payload: error.response,
    })
  }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  })
}
