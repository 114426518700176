import React, { lazy, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Loading from '../../components/shared-components/Loading';
import { ADMIN_AUTH_PREFIX_PATH } from '../../configs/AppConfig'

export default function AdminAuthViews() {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${ADMIN_AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./login`))} />

        <Redirect from={`${ADMIN_AUTH_PREFIX_PATH}`} to={`${ADMIN_AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}
