export const CREATE_QUESTION_FIELD_REQUEST = 'CREATE_QUESTION_FIELD_REQUEST'
export const CREATE_QUESTION_FIELD_SUCCESS = 'CREATE_QUESTION_FIELD_SUCCESS'
export const CREATE_QUESTION_FIELD_RESET = 'CREATE_QUESTION_FIELD_RESET'
export const CREATE_QUESTION_FIELD_FAIL = 'CREATE_QUESTION_FIELD_FAIL'

export const UPDATE_QUESTION_FIELD_REQUEST = 'UPDATE_QUESTION_FIELD_REQUEST'
export const UPDATE_QUESTION_FIELD_SUCCESS = 'UPDATE_QUESTION_FIELD_SUCCESS'
export const UPDATE_QUESTION_FIELD_RESET = 'UPDATE_QUESTION_FIELD_RESET'
export const UPDATE_QUESTION_FIELD_FAIL = 'UPDATE_QUESTION_FIELD_FAIL'

export const DELETE_QUESTION_FIELD_REQUEST = 'DELETE_QUESTION_FIELD_REQUEST'
export const DELETE_QUESTION_FIELD_SUCCESS = 'DELETE_QUESTION_FIELD_SUCCESS'
export const DELETE_QUESTION_FIELD_RESET = 'DELETE_QUESTION_FIELD_RESET'
export const DELETE_QUESTION_FIELD_FAIL = 'DELETE_QUESTION_FIELD_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
