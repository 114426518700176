import {
  ALL_RESELLERS_REQUEST,
  ALL_RESELLERS_SUCCESS,
  ALL_RESELLERS_FAIL,
  GET_RESELLER_STATUS_REQUEST,
  GET_RESELLER_STATUS_SUCCESS,
  GET_RESELLER_STATUS_FAIL,
  CREATE_RESELLER_REQUEST,
  CREATE_RESELLER_SUCCESS,
  CREATE_RESELLER_RESET,
  CREATE_RESELLER_FAIL,
  APPROVE_RESELLER_REQUEST,
  APPROVE_RESELLER_SUCCESS,
  APPROVE_RESELLER_RESET,
  APPROVE_RESELLER_FAIL,
  REJECT_RESELLER_REQUEST,
  REJECT_RESELLER_SUCCESS,
  REJECT_RESELLER_RESET,
  REJECT_RESELLER_FAIL,
  UPDATE_RESELLER_REQUEST,
  UPDATE_RESELLER_SUCCESS,
  UPDATE_RESELLER_RESET,
  UPDATE_RESELLER_FAIL,
  REUPLOAD_VERIFICATION_RESELLER_REQUEST,
  REUPLOAD_VERIFICATION_RESELLER_SUCCESS,
  REUPLOAD_VERIFICATION_RESELLER_RESET,
  REUPLOAD_VERIFICATION_RESELLER_FAIL,
  CLEAR_ERRORS,
} from '../constants/Resellers'

export const allResellersReducer = (state = { profiles: [] }, action) => {
  switch (action.type) {

    case ALL_RESELLERS_REQUEST:
      return {
        loading: true
      }

    case ALL_RESELLERS_SUCCESS:
      return {
        loading: false,
        profiles: action.payload.profiles.data,
        currentPage: action.payload.profiles.meta.current_page,
        totalPages: action.payload.profiles.meta.last_page,
        totalItems: action.payload.profiles.meta.total,
      }

    case ALL_RESELLERS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const resellerStatusReducer = (state = { message: '' }, action) => {
  switch (action.type) {
    case GET_RESELLER_STATUS_REQUEST:
      return {
        loading: true,
      }

    case GET_RESELLER_STATUS_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case GET_RESELLER_STATUS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const createResellerReducer = (state = { profile: {} }, action) => {
  switch (action.type) {

    case CREATE_RESELLER_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_RESELLER_SUCCESS:
      return {
        loading: false,
        profile: action.payload.profile,
        message: action.payload.message
      }

    case CREATE_RESELLER_RESET:
      return {
        loading: false,
        profile: null,
        message: null
      }
    
    case CREATE_RESELLER_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const resellerReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_RESELLER_REQUEST:
    case APPROVE_RESELLER_REQUEST:
    case REJECT_RESELLER_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_RESELLER_SUCCESS:
    case APPROVE_RESELLER_SUCCESS:
    case REJECT_RESELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message
      }

    case UPDATE_RESELLER_RESET:
    case APPROVE_RESELLER_RESET:
    case REJECT_RESELLER_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null
      }

    case UPDATE_RESELLER_FAIL:
    case APPROVE_RESELLER_FAIL:
    case REJECT_RESELLER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const reUploadVerificationResellerReducer = (state = {}, action) => {
  switch (action.type) {

    case REUPLOAD_VERIFICATION_RESELLER_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case REUPLOAD_VERIFICATION_RESELLER_SUCCESS:
      return {
        loading: false,
        message: action.payload.message
      }

    case REUPLOAD_VERIFICATION_RESELLER_RESET:
      return {
        loading: false,
        profile: null,
        message: null
      }
    
    case REUPLOAD_VERIFICATION_RESELLER_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}