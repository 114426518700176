import { Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import './Input.scss'

const { TextArea } = Input

export default function TextAreaInput({
  required = false,
  label,
  flag,
  placeholder,
  minRows,
  name,
  value,
  onChange,
  error,
}) {
  return (
    <div className='normal-input'>
      {label && (
        <div className='d-flex align-items-center gap-2'>
          <label htmlFor={name}>
            {label} {required && <span className='text-danger'>*</span>}
          </label>
          {flag === 'it' ? <img src='/imgs/flags/italy.webp' alt='flag' /> : ''}
          {flag === 'es' ? <img src='/imgs/flags/spain.webp' alt='flag' /> : ''}
          {flag === 'eg' ? <img src='/imgs/flags/egypt.webp' alt='flag' /> : ''}
          {error && <InfoCircleOutlined className='text-danger fs-6 mb-2' />}
        </div>
      )}
      <TextArea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoSize={{ minRows: minRows || 4 }}
      />
      <small className='text-danger fs-12'>{error}</small>
    </div>
  )
}
