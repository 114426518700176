import { Button, Pagination } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import './Pagination.scss'

const PaginationC = ({ current, total, pageSize, customName }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const showTotal = (total) => `Totale ${total} articoli`

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <Button className='btn-pagination'>{t('Back')}</Button>
    }

    if (type === 'next') {
      return <Button className='btn-pagination'>{t('Next')}</Button>
    }

    return originalElement
  }

  return (
    <div id='pagination' className='d-flex justify-content-end mt-3'>
      <Pagination
        pageSize={pageSize ? pageSize : 15}
        defaultCurrent={1}
        total={total}
        showTotal={showTotal}
        itemRender={itemRender}
        onChange={(page) => {
          const searchParams = new URLSearchParams(history.location.search)
          searchParams.set(customName || 'page', page)
          history.push({
            pathname: history.location.pathname,
            search: searchParams.toString(),
          })
        }}
        current={current}
      />
    </div>
  )
}

export default PaginationC
