import { ConfigProvider } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Views from "./views";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import "./sass/styles.css";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// Create a client
const queryClient = new QueryClient();
function App() {
  const { direction } = useSelector((state) => state.theme);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider direction={direction === "rtl" ? "rtl" : "ltr"}>
        <div className={`App ${direction === "rtl" ? "rtl" : "ltr"}`}>
          <Router basename="">
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </QueryParamProvider>
          </Router>
        </div>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
