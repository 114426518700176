import {
  ALL_THANKS_REQUEST,
  ALL_THANKS_SUCCESS,
  ALL_THANKS_FAIL,
  CREATE_THANKS_REQUEST,
  CREATE_THANKS_SUCCESS,
  CREATE_THANKS_RESET,
  CREATE_THANKS_FAIL,
  UPDATE_THANKS_REQUEST,
  UPDATE_THANKS_SUCCESS,
  UPDATE_THANKS_RESET,
  UPDATE_THANKS_FAIL,
  DELETE_THANKS_REQUEST,
  DELETE_THANKS_SUCCESS,
  DELETE_THANKS_RESET,
  DELETE_THANKS_FAIL,
  THANKS_LIST_DETAILS_REQUEST,
  THANKS_LIST_DETAILS_SUCCESS,
  THANKS_LIST_DETAILS_FAIL,
  CREATE_THANKS_LIST_REQUEST,
  CREATE_THANKS_LIST_SUCCESS,
  CREATE_THANKS_LIST_RESET,
  CREATE_THANKS_LIST_FAIL,
  UPDATE_THANKS_LIST_REQUEST,
  UPDATE_THANKS_LIST_SUCCESS,
  UPDATE_THANKS_LIST_RESET,
  UPDATE_THANKS_LIST_FAIL,
  DELETE_THANKS_LIST_REQUEST,
  DELETE_THANKS_LIST_SUCCESS,
  DELETE_THANKS_LIST_RESET,
  DELETE_THANKS_LIST_FAIL,
  CLEAR_ERRORS,
} from '../constants/Thankyou'

export const allThanksReducer = (state = { pages: [] }, action) => {
  switch (action.type) {
    case ALL_THANKS_REQUEST:
      return {
        loading: true,
      }

    case ALL_THANKS_SUCCESS:
      return {
        loading: false,
        pages: action.payload.thank_you_pages,
      }

    case ALL_THANKS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const createThanksReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_THANKS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_THANKS_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case CREATE_THANKS_RESET:
      return {
        loading: false,
        message: null,
      }

    case CREATE_THANKS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const thanksReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_THANKS_REQUEST:
    case DELETE_THANKS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_THANKS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_THANKS_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case DELETE_THANKS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_THANKS_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_THANKS_FAIL:
    case DELETE_THANKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// =================== THANKS LIST ITEMS ===================
export const thanksListDetailsReducer = (
  state = { info_point: {} },
  action
) => {
  switch (action.type) {
    case THANKS_LIST_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case THANKS_LIST_DETAILS_SUCCESS:
      return {
        loading: false,
        info_point: action.payload.info_point,
      }

    case THANKS_LIST_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const createThanksListReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_THANKS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_THANKS_LIST_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case CREATE_THANKS_LIST_RESET:
      return {
        loading: false,
        message: null,
      }

    case CREATE_THANKS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const thanksListReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_THANKS_LIST_REQUEST:
    case DELETE_THANKS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_THANKS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_THANKS_LIST_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case DELETE_THANKS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_THANKS_LIST_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_THANKS_LIST_FAIL:
    case DELETE_THANKS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
