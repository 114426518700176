import axios, { axiosFormData } from '../../configs/APIConfig/axiosInstance'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  PROFILE_DATA_REQUEST,
  PROFILE_DATA_SUCCESS,
  PROFILE_DATA_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  CLEAR_ERRORS,
} from '../constants/Auth'

// LOGIN [user - reseller - admin]
export const login = (user, loginData, callback) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST })

    const { data } = await axios.post(`/${user}/login`, loginData)

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    })
    callback(data.token)
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response,
    })
  }
}

// PROFILE DATA [user - reseller - admin]
export const getProfileData = (user) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_DATA_REQUEST })

    const data = await axios.get(`/${user}/profile`)

    dispatch({
      type: PROFILE_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response) {
      dispatch({
        type: PROFILE_DATA_FAIL,
        payload: error.response,
      })
    } else if (error.request) {
      dispatch({
        type: PROFILE_DATA_FAIL,
        payload: 'Network Error',
      })
    } else {
      dispatch({
        type: PROFILE_DATA_FAIL,
        payload: error.message,
      })
    }
  }
}

// UPDATE ME [user - reseller - admin]
export const updateProfile = (user, profileData, callback) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST })

    const { data } = await axiosFormData.post(`/${user}/profile`, profileData)

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
    callback()
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response,
    })
  }
}

// UPDATE PROFILE FROM ADMIN [user - reseller] NOT ADMIN
export const updateProfileAdmin = (user, profileData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST })

    const { data } = await axiosFormData.post(
      `/admin/${user}/profile`,
      profileData
    )

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response,
    })
  }
}

// UPDATE PASSWORD [user - reseller - admin] - Update Your Password
// Update Other Admin Password: If You send admin_id in body
export const updatePassword = (resetData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST })

    const { data } = await axios.post('/password/edit', resetData)

    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response,
    })
  }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  })
}
