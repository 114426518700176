import { Button, Divider, Popconfirm } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { setModal } from '../../redux/actions/Modal'
import { useDeleteServiceFQA } from '../admin-views/FrequentlyQuestionedAnswers/apis/useDeleteServiceFQA'

import CreateEditQuestion from '../admin-views/FrequentlyQuestionedAnswers/components/CreateEditQuestion'

import './Faqs.scss'

const Faq = ({ ele, hideActions }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(true)

  const iconStyle = {
    fontSize: '1.5rem',
    transform: open
      ? 'rotate(45deg) translateY(3px)'
      : 'rotate(0deg) translateY(3px)',
    transition: 'transform 0.3s ease',
  }

  const { mutate: deleteFaq } = useDeleteServiceFQA()

  return (
    <>
      <div className='faq'>
        <div className='question' onClick={() => setOpen(!open)}>
          <h6>{ele.question}</h6>
          <PlusCircleOutlined style={iconStyle} />
        </div>
        <div className={`answer transition-all ${open ? 'open' : ''}`}>
          <p className='text-dimmed'>{ele.answer || ele.answare}</p>
          {!hideActions && (
            <div className='mt-3 d-flex justify-content-end gap-2'>
              <Button
                className='table-btn primary'
                onClick={() =>
                  dispatch(
                    setModal({
                      title: 'Update Question Answer',
                      content: <CreateEditQuestion selectedElement={ele} />,
                    })
                  )
                }
              >
                {t('Edit')}
              </Button>
              <Popconfirm
                placement='topLeft'
                title={t('Are you sure that you want to delete this item?')}
                okText={t('Confirm')}
                cancelText={'Cancel'}
                onConfirm={() => deleteFaq(ele.id)}
              >
                <Button className='table-btn danger'>{t('Delete')}</Button>
              </Popconfirm>
            </div>
          )}
        </div>
      </div>
      <Divider />
    </>
  )
}

const Faqs = ({ data, hideActions }) => {
  return (
    <div className='faqs'>
      {data.map((ele, i) => (
        <Faq key={i} ele={ele} hideActions={hideActions} />
      ))}
    </div>
  )
}

export default Faqs
