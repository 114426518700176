import {
  ACTIONS_COUNT_REQUEST,
  ACTIONS_COUNT_SUCCESS,
  ACTIONS_COUNT_FAIL,
  CLEAR_ERRORS,
} from '../constants/AdminActionsCount'

export const actionsCountReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ACTIONS_COUNT_REQUEST:
      return {
        loading: true,
      }

    case ACTIONS_COUNT_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
      }

    case ACTIONS_COUNT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}