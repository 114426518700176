import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosFormData } from '../../../../configs/APIConfig/axiosInstance'
import { useDispatch } from 'react-redux'
import { clearModal } from '../../../../redux/actions/Modal'
import { openNotification, errorHandling } from '../../../../utils/utils'

export const useUpdateServiceFQA = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, formValues }) => {
      const response = await axiosFormData.post(`/admin/service_fqa/${id}`, {
        _method: 'PUT',
        ...formValues,
      })
      return response.data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['FQAS'])
      dispatch(clearModal())
      openNotification('success', data.message)
    },
    onError: (error) => {
      const { message } = errorHandling(error)
      openNotification('error', message)
    },
  })
}
