import {
  CREATE_QUESTION_OPTION_REQUEST,
  CREATE_QUESTION_OPTION_SUCCESS,
  CREATE_QUESTION_OPTION_RESET,
  CREATE_QUESTION_OPTION_FAIL,
  UPDATE_QUESTION_OPTION_REQUEST,
  UPDATE_QUESTION_OPTION_SUCCESS,
  UPDATE_QUESTION_OPTION_RESET,
  UPDATE_QUESTION_OPTION_FAIL,
  DELETE_QUESTION_OPTION_REQUEST,
  DELETE_QUESTION_OPTION_SUCCESS,
  DELETE_QUESTION_OPTION_RESET,
  DELETE_QUESTION_OPTION_FAIL,
  CLEAR_ERRORS,
} from '../constants/QuestionOption'

export const createQuestionOptionReducer = (state = { option: {} }, action) => {
  switch (action.type) {
    case CREATE_QUESTION_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_QUESTION_OPTION_SUCCESS:
      return {
        loading: false,
        option: action.payload.option,
      }

    case CREATE_QUESTION_OPTION_RESET:
      return {
        loading: false,
        option: null,
        message: null,
      }

    case CREATE_QUESTION_OPTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const questionOptionReducer = (state = { option: {} }, action) => {
  switch (action.type) {
    case UPDATE_QUESTION_OPTION_REQUEST:
    case DELETE_QUESTION_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_QUESTION_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        option: action.payload.option,
      }

    case UPDATE_QUESTION_OPTION_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      }

    case DELETE_QUESTION_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_QUESTION_OPTION_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_QUESTION_OPTION_FAIL:
    case DELETE_QUESTION_OPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
