import React from 'react'
import AdminAuthViews from '../../views/admin-auth-views'

export default function AdminAuthLayout() {
  return (
    <div className="app-layout">
      <AdminAuthViews />
    </div>
  )
}
