export const ALL_CONTACTS_REQUEST = 'ALL_CONTACTS_REQUEST'
export const ALL_CONTACTS_SUCCESS = 'ALL_CONTACTS_SUCCESS'
export const ALL_CONTACTS_FAIL = 'ALL_CONTACTS_FAIL'

export const CONTACT_DETAILS_REQUEST = 'CONTACT_DETAILS_REQUEST'
export const CONTACT_DETAILS_SUCCESS = 'CONTACT_DETAILS_SUCCESS'
export const CONTACT_DETAILS_FAIL = 'CONTACT_DETAILS_FAIL'

export const REPLY_CONTACT_REQUEST = 'REPLY_CONTACT_REQUEST'
export const REPLY_CONTACT_SUCCESS = 'REPLY_CONTACT_SUCCESS'
export const REPLY_CONTACT_RESET = 'REPLY_CONTACT_RESET'
export const REPLY_CONTACT_FAIL = 'REPLY_CONTACT_FAIL'

export const CLOSE_CONTACT_REQUEST = 'CLOSE_CONTACT_REQUEST'
export const CLOSE_CONTACT_SUCCESS = 'CLOSE_CONTACT_SUCCESS'
export const CLOSE_CONTACT_RESET = 'CLOSE_CONTACT_RESET'
export const CLOSE_CONTACT_FAIL = 'CLOSE_CONTACT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'