export const ALL_PAYMENTS_REQUEST = 'ALL_PAYMENTS_REQUEST'
export const ALL_PAYMENTS_SUCCESS = 'ALL_PAYMENTS_SUCCESS'
export const ALL_PAYMENTS_FAIL = 'ALL_PAYMENTS_FAIL'

export const RESELLER_INVOICES_REQUEST = 'RESELLER_INVOICES_REQUEST'
export const RESELLER_INVOICES_SUCCESS = 'RESELLER_INVOICES_SUCCESS'
export const RESELLER_INVOICES_FAIL = 'RESELLER_INVOICES_FAIL'

export const UPLOAD_INVOICE_REQUEST = 'UPLOAD_INVOICE_REQUEST'
export const UPLOAD_INVOICE_SUCCESS = 'UPLOAD_INVOICE_SUCCESS'
export const UPLOAD_INVOICE_RESET = 'UPLOAD_INVOICE_RESET'
export const UPLOAD_INVOICE_FAIL = 'UPLOAD_INVOICE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'