import { useQuery } from '@tanstack/react-query'
import axios from '../../../../configs/APIConfig/axiosInstance'

const getServiceFQA = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { search, service_slug }] = queryKey
  const params = {}

  if (search) params.search = search
  if (service_slug) params.service_slug = service_slug

  return axios.get(`admin/service_fqa`, { params })
}

export const useGetServiceFQAData = ({ search, service_slug }) => {
  return useQuery({
    queryKey: ['FQAS', { search, service_slug }],
    queryFn: getServiceFQA,
    staleTime: Infinity,
    select: ({ data }) => {
      return data.fqa
    },
  })
}