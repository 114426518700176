import {
  CREATE_FORM_PART_REQUEST,
  CREATE_FORM_PART_SUCCESS,
  CREATE_FORM_PART_RESET,
  CREATE_FORM_PART_FAIL,
  UPDATE_FORM_PART_REQUEST,
  UPDATE_FORM_PART_SUCCESS,
  UPDATE_FORM_PART_RESET,
  UPDATE_FORM_PART_FAIL,
  DELETE_FORM_PART_REQUEST,
  DELETE_FORM_PART_SUCCESS,
  DELETE_FORM_PART_RESET,
  DELETE_FORM_PART_FAIL,
  CLEAR_ERRORS,
} from '../constants/FormPart'

export const createFormPartReducer = (state = { part: {} }, action) => {
  switch (action.type) {
    case CREATE_FORM_PART_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_FORM_PART_SUCCESS:
      return {
        loading: false,
        part: action.payload.part,
      }

    case CREATE_FORM_PART_RESET:
      return {
        loading: false,
        part: null,
        message: null,
      }

    case CREATE_FORM_PART_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const formPartReducer = (state = { form: {} }, action) => {
  switch (action.type) {
    case UPDATE_FORM_PART_REQUEST:
    case DELETE_FORM_PART_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_FORM_PART_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        form: action.payload.form,
      }

    case UPDATE_FORM_PART_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      }

    case DELETE_FORM_PART_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_FORM_PART_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_FORM_PART_FAIL:
    case DELETE_FORM_PART_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}