import { ADMIN_PREFIX_PATH } from '../AppConfig'
import {
  PieChartOutlined,
  LineChartOutlined,
  ApartmentOutlined,
  DatabaseOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  MoneyCollectOutlined,
  CustomerServiceOutlined,
  ContainerOutlined,
  TeamOutlined,
  LikeOutlined,
  HistoryOutlined,
  SettingOutlined,
  BankOutlined,
  ScheduleOutlined,
  ShopOutlined,
} from '@ant-design/icons'

let navTree

if (process.env.REACT_APP_ENVIRONMET !== 'development') {
  navTree = [
    {
      key: 'Home',
      path: `${ADMIN_PREFIX_PATH}/Home`,
      title: 'sidenav.Home',
      icon: <PieChartOutlined />,
      iconActive: <PieChartOutlined />,
      role: 'dashboard:view',
      submenu: [],
    },
    {
      key: 'Statistics',
      path: `${ADMIN_PREFIX_PATH}/Statistics`,
      title: 'Statistics',
      icon: <LineChartOutlined />,
      iconActive: <LineChartOutlined />,
      role: 'dashboard:view',
      submenu: [
        {
          key: 'StatsClients',
          path: `${ADMIN_PREFIX_PATH}/Statistics/clients`,
          title: 'sidenav.Clients',
        },
        {
          key: 'StatsResellers',
          path: `${ADMIN_PREFIX_PATH}/Statistics/Resellers`,
          title: 'sidenav.Resellers',
        },
      ],
    },
    {
      key: 'Forms',
      path: `${ADMIN_PREFIX_PATH}/Forms`,
      title: 'Forms',
      icon: <DatabaseOutlined />,
      iconActive: <DatabaseOutlined />,
      role: 'forms:view',
      submenu: [],
    },
    {
      key: 'Categories',
      path: `${ADMIN_PREFIX_PATH}/Categories`,
      title: 'Categories',
      icon: <ApartmentOutlined />,
      iconActive: <ApartmentOutlined />,
      role: 'category:create',
      submenu: [],
    },
    {
      key: 'Services',
      path: `${ADMIN_PREFIX_PATH}/Services`,
      title: 'homenav.Services',
      icon: <AppstoreOutlined />,
      iconActive: <AppstoreOutlined />,
      role: 'service:create',
      submenu: [],
    },
    {
      key: 'Requests',
      path: `${ADMIN_PREFIX_PATH}/Requests`,
      title: 'sidenav.Requests',
      icon: <FileDoneOutlined />,
      iconActive: <FileDoneOutlined />,
      role: 'requests:view',
      submenu: [],
    },
    {
      key: 'Payments',
      path: `${ADMIN_PREFIX_PATH}/Payments`,
      title: 'sidenav.Payments',
      icon: <MoneyCollectOutlined />,
      iconActive: <MoneyCollectOutlined />,
      role: 'payments:view',
      submenu: [
        {
          key: 'ByRequest',
          path: `${ADMIN_PREFIX_PATH}/Payments/By-Request`,
          title: 'By Request',
        },
        {
          key: 'Invoices',
          path: `${ADMIN_PREFIX_PATH}/Payments/Invoices`,
          title: 'Invoices',
        },
      ],
    },
    {
      key: 'FollowUp',
      path: `${ADMIN_PREFIX_PATH}/Follow-up-Inquiries`,
      title: 'Follow up Inquiries',
      icon: <CustomerServiceOutlined />,
      iconActive: <CustomerServiceOutlined />,
      role: 'ticket:view',
      submenu: [],
    },
    {
      key: 'Blog',
      path: `${ADMIN_PREFIX_PATH}/Blog`,
      title: 'Blog',
      icon: <FileTextOutlined />,
      iconActive: <FileTextOutlined />,
      role: 'blog:create',
      submenu: [],
    },
    {
      key: 'users',
      path: `${ADMIN_PREFIX_PATH}/users`,
      title: 'sidenav.Users',
      icon: <TeamOutlined />,
      iconActive: <TeamOutlined />,
      submenu: [
        {
          key: 'Clients',
          path: `${ADMIN_PREFIX_PATH}/users/Clients`,
          title: 'sidenav.Clients',
          role: 'user:view',
        },
        {
          key: 'Resellers',
          path: `${ADMIN_PREFIX_PATH}/users/Resellers`,
          title: 'sidenav.Resellers',
          role: 'reseller:view',
        },
        {
          key: 'Admins',
          path: `${ADMIN_PREFIX_PATH}/users/Admins`,
          title: 'sidenav.Admins',
          role: 'admin:view',
        },
      ],
    },
    {
      key: 'Thank_you',
      path: `${ADMIN_PREFIX_PATH}/Thank-you`,
      title: 'Thank you',
      icon: <LikeOutlined />,
      iconActive: <LikeOutlined />,
      role: 'thank_you_page:view',
      submenu: [],
    },
    {
      key: 'login_history',
      path: `${ADMIN_PREFIX_PATH}/login-history`,
      title: 'Login History',
      icon: <HistoryOutlined />,
      iconActive: <HistoryOutlined />,
      role: 'admin:view',
      submenu: [],
    },
    {
      key: 'user-packages',
      path: `${ADMIN_PREFIX_PATH}/user-packages`,
      title: 'Pacchetti utente',
      icon: <ShopOutlined />,
      iconActive: <ShopOutlined />,
      role: 'admin:view',
      submenu: [],
    },
    {
      key: 'packages',
      path: `${ADMIN_PREFIX_PATH}/packages`,
      title: 'Packages',
      icon: <ContainerOutlined />,
      iconActive: <ContainerOutlined />,
      role: 'admin:view',
      submenu: [
        {
          key: 'packagesManage',
          path: `${ADMIN_PREFIX_PATH}/packages/packages-management`,
          title: 'Packages Management',
        },
        {
          key: 'resellersManage',
          path: `${ADMIN_PREFIX_PATH}/packages/resellers-management`,
          title: 'Resellers Management',
        },
        {
          key: 'packageRequests',
          path: `${ADMIN_PREFIX_PATH}/packages/packages-requests`,
          title: 'Package Requests',
        },
      ],
    },
    {
      key: 'administration',
      path: `${ADMIN_PREFIX_PATH}/Administration`,
      title: 'Administration',
      icon: <SettingOutlined />,
      iconActive: <SettingOutlined />,
      role: 'admin:view',
      submenu: [
        {
          key: 'ActionsCount',
          path: `${ADMIN_PREFIX_PATH}/Administration/actions-count`,
          title: 'Actions Count',
        },
        {
          key: 'Roles',
          path: `${ADMIN_PREFIX_PATH}/Administration/Roles`,
          title: 'Roles',
        },
        {
          key: 'PermissionsAdmin',
          path: `${ADMIN_PREFIX_PATH}/Administration/Permissions`,
          title: 'Permissions',
        },
        {
          key: 'OperatorSettings',
          path: `${ADMIN_PREFIX_PATH}/Administration/operator-settings`,
          title: "Impostazioni dell'operatore",
        },
      ],
    },
  ]
} else {
  navTree = [
    {
      key: 'Home',
      path: `${ADMIN_PREFIX_PATH}/Home`,
      title: 'sidenav.Home',
      icon: <PieChartOutlined />,
      iconActive: <PieChartOutlined />,
      role: 'dashboard:view',
      submenu: [],
    },
    {
      key: 'Statistics',
      path: `${ADMIN_PREFIX_PATH}/Statistics`,
      title: 'Statistics',
      icon: <LineChartOutlined />,
      iconActive: <LineChartOutlined />,
      role: 'dashboard:view',
      submenu: [
        {
          key: 'StatsClients',
          path: `${ADMIN_PREFIX_PATH}/Statistics/clients`,
          title: 'sidenav.Clients',
        },
        {
          key: 'StatsResellers',
          path: `${ADMIN_PREFIX_PATH}/Statistics/Resellers`,
          title: 'sidenav.Resellers',
        },
      ],
    },
    {
      key: 'Forms',
      path: `${ADMIN_PREFIX_PATH}/Forms`,
      title: 'Forms',
      icon: <DatabaseOutlined />,
      iconActive: <DatabaseOutlined />,
      role: 'forms:view',
      submenu: [],
    },
    {
      key: 'Categories',
      path: `${ADMIN_PREFIX_PATH}/Categories`,
      title: 'Categories',
      icon: <ApartmentOutlined />,
      iconActive: <ApartmentOutlined />,
      role: 'category:create',
      submenu: [],
    },
    {
      key: 'Services',
      path: `${ADMIN_PREFIX_PATH}/Services`,
      title: 'homenav.Services',
      icon: <AppstoreOutlined />,
      iconActive: <AppstoreOutlined />,
      role: 'service:create',
      submenu: [],
    },
    {
      key: 'Requests',
      path: `${ADMIN_PREFIX_PATH}/Requests`,
      title: 'sidenav.Requests',
      icon: <FileDoneOutlined />,
      iconActive: <FileDoneOutlined />,
      role: 'requests:view',
      submenu: [
        {
          key: 'general',
          path: `${ADMIN_PREFIX_PATH}/Requests`,
          title: 'CAF e patronato',
        },
        {
          key: 'insurance',
          path: `${ADMIN_PREFIX_PATH}/Requests/insurance`,
          title: 'Assicurazione',
        },
        {
          key: 'home-utilities',
          path: `${ADMIN_PREFIX_PATH}/Requests/home-utilities`,
          title: 'Utenze Casa',
        },
        {
          key: 'translation',
          path: `${ADMIN_PREFIX_PATH}/Requests/translation`,
          title: 'Traduzione',
        },
      ],
    },
    {
      key: 'FEA',
      path: `${ADMIN_PREFIX_PATH}/FEA`,
      title: 'FEA',
      icon: <ScheduleOutlined />,
      iconActive: <ScheduleOutlined />,
      role: 'requests:view',
      submenu: [],
    },
    {
      key: 'Payments',
      path: `${ADMIN_PREFIX_PATH}/Payments`,
      title: 'sidenav.Payments',
      icon: <MoneyCollectOutlined />,
      iconActive: <MoneyCollectOutlined />,
      role: 'payments:view',
      submenu: [
        {
          key: 'ByRequest',
          path: `${ADMIN_PREFIX_PATH}/Payments/By-Request`,
          title: 'By Request',
        },
        {
          key: 'Invoices',
          path: `${ADMIN_PREFIX_PATH}/Payments/Invoices`,
          title: 'Invoices',
        },
      ],
    },
    {
      key: 'FollowUp',
      path: `${ADMIN_PREFIX_PATH}/Follow-up-Inquiries`,
      title: 'Follow up Inquiries',
      icon: <CustomerServiceOutlined />,
      iconActive: <CustomerServiceOutlined />,
      role: 'ticket:view',
      submenu: [],
    },
    {
      key: 'Blog',
      path: `${ADMIN_PREFIX_PATH}/Blog`,
      title: 'Blog',
      icon: <FileTextOutlined />,
      iconActive: <FileTextOutlined />,
      role: 'blog:create',
      submenu: [],
    },
    {
      key: 'users',
      path: `${ADMIN_PREFIX_PATH}/users`,
      title: 'sidenav.Users',
      icon: <TeamOutlined />,
      iconActive: <TeamOutlined />,
      submenu: [
        {
          key: 'Clients',
          path: `${ADMIN_PREFIX_PATH}/users/Clients`,
          title: 'sidenav.Clients',
          role: 'user:view',
        },
        {
          key: 'Resellers',
          path: `${ADMIN_PREFIX_PATH}/users/Resellers`,
          title: 'sidenav.Resellers',
          role: 'reseller:view',
        },
        {
          key: 'Admins',
          path: `${ADMIN_PREFIX_PATH}/users/Admins`,
          title: 'sidenav.Admins',
          role: 'admin:view',
        },
      ],
    },
    {
      key: 'Thank_you',
      path: `${ADMIN_PREFIX_PATH}/Thank-you`,
      title: 'Thank you',
      icon: <LikeOutlined />,
      iconActive: <LikeOutlined />,
      role: 'thank_you_page:view',
      submenu: [],
    },
    {
      key: 'login_history',
      path: `${ADMIN_PREFIX_PATH}/login-history`,
      title: 'Login History',
      icon: <HistoryOutlined />,
      iconActive: <HistoryOutlined />,
      role: 'admin:view',
      submenu: [],
    },
    {
      key: 'user-packages',
      path: `${ADMIN_PREFIX_PATH}/user-packages`,
      title: 'Pacchetti utente',
      icon: <ShopOutlined />,
      iconActive: <ShopOutlined />,
      role: 'admin:view',
      submenu: [],
    },
    {
      key: 'packages',
      path: `${ADMIN_PREFIX_PATH}/packages`,
      title: 'Packages',
      icon: <ContainerOutlined />,
      iconActive: <ContainerOutlined />,
      role: 'admin:view',
      submenu: [
        {
          key: 'packagesManage',
          path: `${ADMIN_PREFIX_PATH}/packages/packages-management`,
          title: 'Packages Management',
        },
        {
          key: 'resellersManage',
          path: `${ADMIN_PREFIX_PATH}/packages/resellers-management`,
          title: 'Resellers Management',
        },
        {
          key: 'packageRequests',
          path: `${ADMIN_PREFIX_PATH}/packages/packages-requests`,
          title: 'Package Requests',
        },
      ],
    },
    {
      key: 'administration',
      path: `${ADMIN_PREFIX_PATH}/Administration`,
      title: 'Administration',
      icon: <SettingOutlined />,
      iconActive: <SettingOutlined />,
      role: 'admin:view',
      submenu: [
        {
          key: 'coupons',
          path: `${ADMIN_PREFIX_PATH}/Administration/coupons`,
          title: 'coupon e codice promozionale',
        },
        {
          key: 'ActionsCount',
          path: `${ADMIN_PREFIX_PATH}/Administration/actions-count`,
          title: 'Actions Count',
        },
        {
          key: 'Roles',
          path: `${ADMIN_PREFIX_PATH}/Administration/Roles`,
          title: 'Roles',
        },
        {
          key: 'PermissionsAdmin',
          path: `${ADMIN_PREFIX_PATH}/Administration/Permissions`,
          title: 'Permissions',
        },
        {
          key: 'OperatorSettings',
          path: `${ADMIN_PREFIX_PATH}/Administration/operator-settings`,
          title: "Impostazioni dell'operatore",
        },
        {
          key: 'zipCodes',
          path: `${ADMIN_PREFIX_PATH}/Administration/zip-codes`,
          title: 'Codici postali',
        },
      ],
    },
    {
      key: 'EG_companies',
      path: `${ADMIN_PREFIX_PATH}/electricity-gas-companies`,
      title: 'electricity and gas companies',
      icon: <BankOutlined />,
      iconActive: <BankOutlined />,
      role: 'admin:view',
      submenu: [],
    },
  ]
}

export default navTree
