import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Loading from '../../components/shared-components/Loading'
import {
  ADMIN_AUTH_PREFIX_PATH,
  ADMIN_PREFIX_PATH,
} from '../../configs/AppConfig'
import { useSelector } from 'react-redux'

function AdminViews() {
  const history = useHistory()
  const token = Cookies.get('SMARTCAF_JWT_TOKEN')?.split(' ')[1]

  const { error } = useSelector((state) => state.myProfile)

  useEffect(() => {
    if (!token || error) {
      history.push(`${ADMIN_AUTH_PREFIX_PATH}`)
    }
  }, [token, error, history])

  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route
          path={`${ADMIN_PREFIX_PATH}/Home`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Statistics`}
          component={lazy(() => import(`./statistics`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Forms`}
          component={lazy(() => import(`./forms`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Categories`}
          component={lazy(() => import(`./categories`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Services`}
          component={lazy(() => import(`./services`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Requests`}
          component={lazy(() => import(`./requests`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/FEA/firmare`}
          component={lazy(() => import(`./operatorSignature/sign`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/FEA`}
          component={lazy(() => import(`./operatorSignature`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Payments`}
          component={lazy(() => import(`./payments`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Blog`}
          component={lazy(() => import(`./blog`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/user-packages`}
          component={lazy(() => import(`./userPackages`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Follow-up-Inquiries`}
          component={lazy(() => import(`./followupInquiries`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/login-history`}
          component={lazy(() => import(`./loginHistory`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Thank-you`}
          component={lazy(() => import(`./thankYou`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Administration`}
          component={lazy(() => import(`./administration`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/packages`}
          component={lazy(() => import(`./packages`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/Notifications`}
          component={lazy(() => import(`./notifications`))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/electricity-gas-companies`}
          component={lazy(() => import(`./electricityGasCompanies`))}
        />

        <Redirect
          from={`${ADMIN_PREFIX_PATH}`}
          to={`${ADMIN_PREFIX_PATH}/Home`}
        />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AdminViews)
