export const CREATE_QUESTION_OPTION_REQUEST = 'CREATE_QUESTION_OPTION_REQUEST'
export const CREATE_QUESTION_OPTION_SUCCESS = 'CREATE_QUESTION_OPTION_SUCCESS'
export const CREATE_QUESTION_OPTION_RESET = 'CREATE_QUESTION_OPTION_RESET'
export const CREATE_QUESTION_OPTION_FAIL = 'CREATE_QUESTION_OPTION_FAIL'

export const UPDATE_QUESTION_OPTION_REQUEST = 'UPDATE_QUESTION_OPTION_REQUEST'
export const UPDATE_QUESTION_OPTION_SUCCESS = 'UPDATE_QUESTION_OPTION_SUCCESS'
export const UPDATE_QUESTION_OPTION_RESET = 'UPDATE_QUESTION_OPTION_RESET'
export const UPDATE_QUESTION_OPTION_FAIL = 'UPDATE_QUESTION_OPTION_FAIL'

export const DELETE_QUESTION_OPTION_REQUEST = 'DELETE_QUESTION_OPTION_REQUEST'
export const DELETE_QUESTION_OPTION_SUCCESS = 'DELETE_QUESTION_OPTION_SUCCESS'
export const DELETE_QUESTION_OPTION_RESET = 'DELETE_QUESTION_OPTION_RESET'
export const DELETE_QUESTION_OPTION_FAIL = 'DELETE_QUESTION_OPTION_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
