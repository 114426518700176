import React, { useState, useEffect, useRef } from 'react'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import countries from '../../configs/Countries'
import { ReactComponent as Arrow } from '../shared-components/svgs/arrow.svg'
import { useDispatch, useSelector } from 'react-redux'
import { onDirectionChange, updateLanguage } from '../../redux/actions/Theme'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'

export default function Language() {
  const dispatch = useDispatch()
  const languageRef = useRef(null)

  const { profile } = useSelector((state) => state.myProfile)

  const [isOpen, setIsOpen] = useState(false)
  const [country, setCountry] = useState(null)

  useOutsideAlerter(languageRef, () => setIsOpen(false))

  // SET COUNTRY FROM PROFILE
  useEffect(() => {
    if (profile) {
      if (Object.keys(profile).length === 0) {
        const lang = Cookies.get('i18next') || 'it'
        setCountry(lang)
      } else {
        i18next.changeLanguage(profile.lang)
        Cookies.set('i18next', profile.lang)
        setCountry(profile.lang)
        if (profile.lang === 'ar') {
          dispatch(onDirectionChange('rtl'))
          document.body.dir = 'rtl'
        } else {
          document.body.dir = 'ltr'
          dispatch(onDirectionChange('ltr'))
        }
      }
    }
  }, [dispatch, profile])

  return (
    <div ref={languageRef} className={`nav-language ${isOpen && 'open'}`}>
      <div className='header' onClick={() => setIsOpen(!isOpen)}>
        {countries.map((e) => {
          if (e.code === country) {
            return (
              <div key={e.key}>
                <div className='flag'>
                  <img src={e.image} alt={e.name} />
                </div>
                <p className='language'>{e.apprev}</p>
                <Arrow />
              </div>
            )
          }
          return null
        })}
      </div>

      <div className='dropdown'>
        {countries.map((e) => {
          if (e.code !== country) {
            return (
              <div
                key={e.key}
                className='language'
                onClick={() => {
                  if (Object.keys(profile).length === 0) {
                    Cookies.set('i18next', e.code)
                    setIsOpen(false)
                    i18next.changeLanguage(e.code)
                    setCountry(e.code)
                    window.location.reload()
                  } else {
                    dispatch(
                      updateLanguage({ _method: 'PUT', lang: e.code }, () => {
                        Cookies.set('i18next', e.code)
                        setIsOpen(false)
                        i18next.changeLanguage(e.code)
                        dispatch(onDirectionChange(e.dir))
                        window.location.reload()
                      })
                    )
                  }
                }}
              >
                <img src={e.image} alt={e.name} />
                {e.apprev}
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
