import { SET_MODAL, CLEAR_MODAL, SET_MODAL_TYPE } from '../constants/Modal'

export const setModal = ({ title, description, content, width }) => ({
  type: SET_MODAL,
  payload: { title, description, content, width },
})

export const clearModal = () => ({
  type: CLEAR_MODAL,
})

export const setModalType = (modalType) => ({
  type: SET_MODAL_TYPE,
  payload: { modalType },
})
