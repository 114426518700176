const NavItem = ({ title, icon, onClick }) => {
  return (
    <div
      className='d-flex align-items-center gap-2 py-12 px-3'
      onClick={onClick}
    >
      {icon}
      <span className='fs-6 fw-600 text-capitalize'>{title}</span>
    </div>
  )
}

export default NavItem
