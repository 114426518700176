import { message, notification } from 'antd'
import moment from 'moment'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState } from 'draft-js'

// THIS IS A GENERAL FUNCTION WHICH HANDLES SETTING FORMVALUES IN FORMDATA, BUT NO FILES INCLUDED.
// IT ASSUMES THAT ALL FILES ARE PRE-UPLOADED
export function appendFormValues(formData, formValues) {
  Object.entries(formValues).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      // Check for non-null and non-undefined values
      if (Array.isArray(value)) {
        // If the value is an array, append each item separately
        value.forEach((item) => {
          if (item !== null && item !== undefined) {
            // Also check each item in the array
            formData.append(`${key}[]`, item)
          }
        })
      } else {
        // Check if the value matches the DD/MM/YYYY date format
        const datePattern =
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/
        if (typeof value === 'string' && datePattern.test(value)) {
          // Replace '/' with '-' in the date string
          const modifiedDate = value.replace(/\//g, '-')
          formData.set(key, modifiedDate)
        } else {
          // If the value is not an array and does not match the date pattern, set it directly
          formData.set(key, value)
        }
      }
    }
  })
}

export const appendFormData = (profileFields, formData, values) => {
  const dateFields = ['birth_date', 'nid_expire_at', 'residence_expire_at']

  profileFields.forEach((field) => {
    if (values[field]) {
      // Check if the field is one of the specific date fields
      if (dateFields.includes(field)) {
        // Replace '/' with '-' in the date string
        const modifiedDate = values[field].replace(/\//g, '-')
        formData.set(field, modifiedDate)
      } else {
        // For other fields, set the value as it is
        formData.set(field, values[field])
      }
    }
  })
}

export const appendFiles = (formData, fieldName, files) => {
  files.forEach((file) => formData.append(fieldName, file))
}

// ANTD DATEPICKER VALIDATION
export function disabledDateBeforeToday(current) {
  return current && current < moment().startOf('day')
}

export function disabledDateAfterToday(current) {
  return current && current > moment().startOf('day')
}

// DEBOUNCE FUNCTION
export const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

// THIS FUNCTION USED IF THE BACKEND RETURNED DATE IN THIS FORMAT 2027-02-11 00:00:00
export function convertDateFormat(datetimeString) {
  if (!datetimeString) return null
  
  // Extract the date part (before the space)
  const datePart = datetimeString.split(' ')[0]

  // Split the date into its components
  const [year, month, day] = datePart.split('-')

  // Reformat to MM/DD/YYYY
  const formattedDate = `${month}/${day}/${year}`

  return formattedDate
}

// Error Handling
export const errorHandling = (error) => {
  if (error.response) {
    // Check for message and errors object
    const message = error.response.data.message || 'Errore sconosciuto'
    const errors = error.response.data.errors

    if (errors) {
      // Map through each key in the errors object and take only the first error message
      const firstErrors = Object.keys(errors).reduce((acc, key) => {
        acc[key] = errors[key][0]
        return acc
      }, {})

      // Return both message and the modified errors object
      return { message, errors: firstErrors }
    }

    // If only message is available
    return { message }
  } else if (error.request) {
    // Error related to the request made but no response was received
    return { message: `Errore nella richiesta: ${error.message}` }
  } else {
    // Other kinds of errors (e.g., setup issues)
    return { message: error.message }
  }
}

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      // Handle successful copying here, like showing a notification
      message.success('Testo copiato negli appunti')
    })
    .catch((err) => {
      // Handle errors here
      message.error('Impossibile copiare il testo: ', err)
    })
}

export const openNotification = (type, description) => {
  notification.open({
    closeIcon: null,
    description,
    type,
  })
}

export const initializeEditorState = (htmlString) => {
  const blocksFromHtml = htmlToDraft(htmlString)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  )
  return EditorState.createWithContent(contentState)
}
