import {
  ALL_FORMS_REQUEST,
  ALL_FORMS_SUCCESS,
  ALL_FORMS_FAIL,
  FORM_DETAILS_REQUEST,
  FORM_DETAILS_SUCCESS,
  FORM_DETAILS_FAIL,
  CREATE_FORM_REQUEST,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_RESET,
  CREATE_FORM_FAIL,
  UPDATE_FORM_REQUEST,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_RESET,
  UPDATE_FORM_FAIL,
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_RESET,
  DELETE_FORM_FAIL,
  SUBMIT_FORM_REQUEST,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_RESET,
  SUBMIT_FORM_FAIL,
  DRAFT_FORM_REQUEST,
  DRAFT_FORM_SUCCESS,
  DRAFT_FORM_RESET,
  DRAFT_FORM_FAIL,
  RESET_FORM_REQUEST,
  RESET_FORM_SUCCESS,
  RESET_FORM_RESET,
  RESET_FORM_FAIL,
  DELETE_DRAFT_FILE_REQUEST,
  DELETE_DRAFT_FILE_SUCCESS,
  DELETE_DRAFT_FILE_RESET,
  DELETE_DRAFT_FILE_FAIL,
  CLEAR_ERRORS,
} from '../constants/CustomForms'

export const createFormReducer = (state = { form: {} }, action) => {
  switch (action.type) {
    case CREATE_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_FORM_SUCCESS:
      return {
        loading: false,
        form: action.payload.form,
      }

    case CREATE_FORM_RESET:
      return {
        loading: false,
        form: null,
        message: null,
      }

    case CREATE_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const allFormsReducer = (state = { forms: [] }, action) => {
  switch (action.type) {
    case ALL_FORMS_REQUEST:
      return {
        loading: true,
      }

    case ALL_FORMS_SUCCESS:
      return {
        loading: false,
        forms: action.payload.forms.data,
        totalItems: action.payload.forms.meta.total,
      }

    case ALL_FORMS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const formDetailsReducer = (state = { form: {} }, action) => {
  switch (action.type) {
    case FORM_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case FORM_DETAILS_SUCCESS:
      return {
        loading: false,
        form: action.payload.form,
      }

    case FORM_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const formReducer = (state = { form: {} }, action) => {
  switch (action.type) {
    case UPDATE_FORM_REQUEST:
    case DELETE_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        form: action.payload.form,
      }

    case UPDATE_FORM_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      }

    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_FORM_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_FORM_FAIL:
    case DELETE_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const submitFormReducer = (state = { request: {} }, action) => {
  switch (action.type) {
    case SUBMIT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case SUBMIT_FORM_SUCCESS:
      return {
        loading: false,
        request: action.payload.request,
      }

    case SUBMIT_FORM_RESET:
      return {
        loading: false,
        request: null,
      }

    case SUBMIT_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const draftFormReducer = (state = {}, action) => {
  switch (action.type) {
    case DRAFT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case DRAFT_FORM_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case DRAFT_FORM_RESET:
      return {
        loading: false,
        message: null,
      }

    case DRAFT_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const resetFormReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case RESET_FORM_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case RESET_FORM_RESET:
      return {
        loading: false,
        message: null,
      }

    case RESET_FORM_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const deleteDraftFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DRAFT_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case DELETE_DRAFT_FILE_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case DELETE_DRAFT_FILE_RESET:
      return {
        loading: false,
        message: null,
      }

    case DELETE_DRAFT_FILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
