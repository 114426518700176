export const ALL_RESELLERS_REQUEST = 'ALL_RESELLERS_REQUEST'
export const ALL_RESELLERS_SUCCESS = 'ALL_RESELLERS_SUCCESS'
export const ALL_RESELLERS_FAIL = 'ALL_RESELLERS_FAIL'

export const GET_RESELLER_STATUS_REQUEST = 'GET_RESELLER_STATUS_REQUEST'
export const GET_RESELLER_STATUS_SUCCESS = 'GET_RESELLER_STATUS_SUCCESS'
export const GET_RESELLER_STATUS_FAIL = 'GET_RESELLER_STATUS_FAIL'

export const CREATE_RESELLER_REQUEST = 'CREATE_RESELLER_REQUEST'
export const CREATE_RESELLER_SUCCESS = 'CREATE_RESELLER_SUCCESS'
export const CREATE_RESELLER_RESET = 'CREATE_RESELLER_RESET'
export const CREATE_RESELLER_FAIL = 'CREATE_RESELLER_FAIL'

export const APPROVE_RESELLER_REQUEST = 'APPROVE_RESELLER_REQUEST'
export const APPROVE_RESELLER_SUCCESS = 'APPROVE_RESELLER_SUCCESS'
export const APPROVE_RESELLER_RESET = 'APPROVE_RESELLER_RESET'
export const APPROVE_RESELLER_FAIL = 'APPROVE_RESELLER_FAIL'

export const REJECT_RESELLER_REQUEST = 'REJECT_RESELLER_REQUEST'
export const REJECT_RESELLER_SUCCESS = 'REJECT_RESELLER_SUCCESS'
export const REJECT_RESELLER_RESET = 'REJECT_RESELLER_RESET'
export const REJECT_RESELLER_FAIL = 'REJECT_RESELLER_FAIL'

export const UPDATE_RESELLER_REQUEST = 'UPDATE_RESELLER_REQUEST'
export const UPDATE_RESELLER_SUCCESS = 'UPDATE_RESELLER_SUCCESS'
export const UPDATE_RESELLER_RESET = 'UPDATE_RESELLER_RESET'
export const UPDATE_RESELLER_FAIL = 'UPDATE_RESELLER_FAIL'

export const REUPLOAD_VERIFICATION_RESELLER_REQUEST = 'REUPLOAD_VERIFICATION_RESELLER_REQUEST'
export const REUPLOAD_VERIFICATION_RESELLER_SUCCESS = 'REUPLOAD_VERIFICATION_RESELLER_SUCCESS'
export const REUPLOAD_VERIFICATION_RESELLER_RESET = 'REUPLOAD_VERIFICATION_RESELLER_RESET'
export const REUPLOAD_VERIFICATION_RESELLER_FAIL = 'REUPLOAD_VERIFICATION_RESELLER_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'