export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FAIL = 'DASHBOARD_FAIL'

export const SERVICES_GRAPH_REQUEST = 'SERVICES_GRAPH_REQUEST'
export const SERVICES_GRAPH_SUCCESS = 'SERVICES_GRAPH_SUCCESS'
export const SERVICES_GRAPH_FAIL = 'SERVICES_GRAPH_FAIL'

export const USERS_GRAPH_REQUEST = 'USERS_GRAPH_REQUEST'
export const USERS_GRAPH_SUCCESS = 'USERS_GRAPH_SUCCESS'
export const USERS_GRAPH_FAIL = 'USERS_GRAPH_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'