import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Dropdown, List, Button } from 'antd'
import Pusher from 'pusher-js'
import { useDispatch, useSelector } from 'react-redux'
import {
  queryNotifications,
  markAsSeen,
} from '../../redux/actions/Notifications'
import { ReactComponent as Bell } from '../shared-components/svgs/notifications.svg'
import { UPDATE_NOTIFICATION_RESET } from '../../redux/constants/Notification'
import { ADMIN_PREFIX_PATH } from '../../configs/AppConfig'

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size='small'
      itemLayout='horizontal'
      dataSource={list}
      renderItem={(item) => (
        <List.Item
          className={`list-clickable ${item.seen === 0 ? 'unseen' : ''}`}
        >
          <div>
            <span className='title'>{item.title} </span>
            <span className='description'>{item.body}</span>
          </div>
          <small>{item.created_at}</small>
        </List.Item>
      )}
    />
  ) : (
    <div className='empty-notification'>
      <img
        src='https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg'
        alt='empty'
      />
      <p>You have viewed all notifications</p>
    </div>
  )
}

export default function NavNotification() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const token = Cookies.get('SMARTCAF_JWT_TOKEN')

  const { notifications } = useSelector((state) => state.allNotifications)

  const [visible, setVisible] = useState(false)
  const [notificationArr, setNotificationArr] = useState([])

  useEffect(() => {
    dispatch(queryNotifications())
  }, [dispatch])

  useEffect(() => {
    if (notifications) {
      setNotificationArr(notifications)
    }
  }, [notifications])

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      authEndpoint: `https://backend.smartcaf.it/api/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: token,
        },
      },
    })

    const channel = pusher.subscribe(`private-notification_admin`)

    // Notification
    channel.bind('notification', function (data) {
      setNotificationArr((notificationArr) => [
        data.notification,
        ...notificationArr,
      ])
    })

    return () => {
      pusher.unsubscribe('private-notification_admin')
    }
  }, [token])

  const handleVisibleChange = (flag) => {
    if (!flag) {
      dispatch(
        markAsSeen(() => {
          dispatch({ type: UPDATE_NOTIFICATION_RESET })
          dispatch(queryNotifications())
        })
      )
    }
    setVisible(flag)
  }

  const notificationList = (
    <div className='nav-notification'>
      {notificationArr && (
        <div className='nav-notification-body'>
          {getNotificationBody(notificationArr)}
        </div>
      )}
      {notificationArr?.length > 0 ? (
        <div className='nav-notification-footer'>
          <Button
            onClick={() => {
              history.push(`${ADMIN_PREFIX_PATH}/Notifications`)
              setVisible(false)
            }}
          >
            {t('View all')}
          </Button>
        </div>
      ) : null}
    </div>
  )

  return (
    <Dropdown
      placement='bottomRight'
      overlay={notificationList}
      className='nav-notifications'
      onOpenChange={handleVisibleChange}
      open={visible}
      trigger={['click']}
    >
      <div
        className={`${
          notificationArr && notificationArr[0]?.seen === 0 ? 'unseen' : ''
        } `}
      >
        <Bell />
      </div>
    </Dropdown>
  )
}
