export const BLOG_CATEGORIES_REQUEST = 'BLOG_CATEGORIES_REQUEST'
export const BLOG_CATEGORIES_SUCCESS = 'BLOG_CATEGORIES_SUCCESS'
export const BLOG_CATEGORIES_FAIL = 'BLOG_CATEGORIES_FAIL'

export const LANGS_CATEGORY_DETAILS_REQUEST = 'LANGS_CATEGORY_DETAILS_REQUEST'
export const LANGS_CATEGORY_DETAILS_SUCCESS = 'LANGS_CATEGORY_DETAILS_SUCCESS'
export const LANGS_CATEGORY_DETAILS_FAIL = 'LANGS_CATEGORY_DETAILS_FAIL'

export const CREATE_BLOG_CATEGORY_REQUEST = 'CREATE_BLOG_CATEGORY_REQUEST'
export const CREATE_BLOG_CATEGORY_SUCCESS = 'CREATE_BLOG_CATEGORY_SUCCESS'
export const CREATE_BLOG_CATEGORY_RESET = 'CREATE_BLOG_CATEGORY_RESET'
export const CREATE_BLOG_CATEGORY_FAIL = 'CREATE_BLOG_CATEGORY_FAIL'

export const UPDATE_BLOG_CATEGORY_REQUEST = 'UPDATE_BLOG_CATEGORY_REQUEST'
export const UPDATE_BLOG_CATEGORY_SUCCESS = 'UPDATE_BLOG_CATEGORY_SUCCESS'
export const UPDATE_BLOG_CATEGORY_RESET = 'UPDATE_BLOG_CATEGORY_RESET'
export const UPDATE_BLOG_CATEGORY_FAIL = 'UPDATE_BLOG_CATEGORY_FAIL'

export const DELETE_BLOG_CATEGORY_REQUEST = 'DELETE_BLOG_CATEGORY_REQUEST'
export const DELETE_BLOG_CATEGORY_SUCCESS = 'DELETE_BLOG_CATEGORY_SUCCESS'
export const DELETE_BLOG_CATEGORY_RESET = 'DELETE_BLOG_CATEGORY_RESET'
export const DELETE_BLOG_CATEGORY_FAIL = 'DELETE_BLOG_CATEGORY_FAIL'

export const ALL_BLOGS_REQUEST = 'ALL_BLOGS_REQUEST'
export const ALL_BLOGS_SUCCESS = 'ALL_BLOGS_SUCCESS'
export const ALL_BLOGS_FAIL = 'ALL_BLOGS_FAIL'

export const NEW_BLOGS_REQUEST = 'NEW_BLOGS_REQUEST'
export const NEW_BLOGS_SUCCESS = 'NEW_BLOGS_SUCCESS'
export const NEW_BLOGS_FAIL = 'NEW_BLOGS_FAIL'

export const BLOG_DETAILS_REQUEST = 'BLOG_DETAILS_REQUEST'
export const BLOG_DETAILS_SUCCESS = 'BLOG_DETAILS_SUCCESS'
export const BLOG_DETAILS_FAIL = 'BLOG_DETAILS_FAIL'

export const LANGS_BLOG_DETAILS_REQUEST = 'LANGS_BLOG_DETAILS_REQUEST'
export const LANGS_BLOG_DETAILS_SUCCESS = 'LANGS_BLOG_DETAILS_SUCCESS'
export const LANGS_BLOG_DETAILS_FAIL = 'LANGS_BLOG_DETAILS_FAIL'

export const CREATE_BLOG_REQUEST = 'CREATE_BLOG_REQUEST'
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS'
export const CREATE_BLOG_RESET = 'CREATE_BLOG_RESET'
export const CREATE_BLOG_FAIL = 'CREATE_BLOG_FAIL'

export const UPDATE_BLOG_REQUEST = 'UPDATE_BLOG_REQUEST'
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS'
export const UPDATE_BLOG_RESET = 'UPDATE_BLOG_RESET'
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL'

export const DELETE_BLOG_REQUEST = 'DELETE_BLOG_REQUEST'
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS'
export const DELETE_BLOG_RESET = 'DELETE_BLOG_RESET'
export const DELETE_BLOG_FAIL = 'DELETE_BLOG_FAIL'

export const BLOG_INDEX_DETAILS_REQUEST = 'BLOG_INDEX_DETAILS_REQUEST'
export const BLOG_INDEX_DETAILS_SUCCESS = 'BLOG_INDEX_DETAILS_SUCCESS'
export const BLOG_INDEX_DETAILS_FAIL = 'BLOG_INDEX_DETAILS_FAIL'

export const CREATE_BLOG_INDEX_REQUEST = 'CREATE_BLOG_INDEX_REQUEST'
export const CREATE_BLOG_INDEX_SUCCESS = 'CREATE_BLOG_INDEX_SUCCESS'
export const CREATE_BLOG_INDEX_RESET = 'CREATE_BLOG_INDEX_RESET'
export const CREATE_BLOG_INDEX_FAIL = 'CREATE_BLOG_INDEX_FAIL'

export const UPDATE_BLOG_INDEX_REQUEST = 'UPDATE_BLOG_INDEX_REQUEST'
export const UPDATE_BLOG_INDEX_SUCCESS = 'UPDATE_BLOG_INDEX_SUCCESS'
export const UPDATE_BLOG_INDEX_RESET = 'UPDATE_BLOG_INDEX_RESET'
export const UPDATE_BLOG_INDEX_FAIL = 'UPDATE_BLOG_INDEX_FAIL'

export const DELETE_BLOG_INDEX_REQUEST = 'DELETE_BLOG_INDEX_REQUEST'
export const DELETE_BLOG_INDEX_SUCCESS = 'DELETE_BLOG_INDEX_SUCCESS'
export const DELETE_BLOG_INDEX_RESET = 'DELETE_BLOG_INDEX_RESET'
export const DELETE_BLOG_INDEX_FAIL = 'DELETE_BLOG_INDEX_FAIL'

export const BLOG_PARAGRAPH_DETAILS_REQUEST = 'BLOG_PARAGRAPH_DETAILS_REQUEST'
export const BLOG_PARAGRAPH_DETAILS_SUCCESS = 'BLOG_PARAGRAPH_DETAILS_SUCCESS'
export const BLOG_PARAGRAPH_DETAILS_FAIL = 'BLOG_PARAGRAPH_DETAILS_FAIL'

export const CREATE_BLOG_PARAGRAPH_REQUEST = 'CREATE_BLOG_PARAGRAPH_REQUEST'
export const CREATE_BLOG_PARAGRAPH_SUCCESS = 'CREATE_BLOG_PARAGRAPH_SUCCESS'
export const CREATE_BLOG_PARAGRAPH_RESET = 'CREATE_BLOG_PARAGRAPH_RESET'
export const CREATE_BLOG_PARAGRAPH_FAIL = 'CREATE_BLOG_PARAGRAPH_FAIL'

export const UPDATE_BLOG_PARAGRAPH_REQUEST = 'UPDATE_BLOG_PARAGRAPH_REQUEST'
export const UPDATE_BLOG_PARAGRAPH_SUCCESS = 'UPDATE_BLOG_PARAGRAPH_SUCCESS'
export const UPDATE_BLOG_PARAGRAPH_RESET = 'UPDATE_BLOG_PARAGRAPH_RESET'
export const UPDATE_BLOG_PARAGRAPH_FAIL = 'UPDATE_BLOG_PARAGRAPH_FAIL'

export const DELETE_BLOG_PARAGRAPH_REQUEST = 'DELETE_BLOG_PARAGRAPH_REQUEST'
export const DELETE_BLOG_PARAGRAPH_SUCCESS = 'DELETE_BLOG_PARAGRAPH_SUCCESS'
export const DELETE_BLOG_PARAGRAPH_RESET = 'DELETE_BLOG_PARAGRAPH_RESET'
export const DELETE_BLOG_PARAGRAPH_FAIL = 'DELETE_BLOG_PARAGRAPH_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'