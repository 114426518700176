import { Route, Switch, Redirect } from 'react-router-dom'
import AdminLayout from '../layouts/admin-layout'
import AdminAuthLayout from '../layouts/admin-auth-layout'
import { ADMIN_AUTH_PREFIX_PATH, ADMIN_PREFIX_PATH } from '../configs/AppConfig'

export default function Views() {
  return (
    <Switch>
      <Route path={ADMIN_PREFIX_PATH}>
        <AdminLayout />
      </Route>
      <Route path={ADMIN_AUTH_PREFIX_PATH}>
        <AdminAuthLayout />
      </Route>
      <Route path='*'>
        <Redirect to={'/admin'} />
      </Route>
    </Switch>
  )
}
