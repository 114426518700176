export const CREATE_FORM_PART_REQUEST = 'CREATE_FORM_PART_REQUEST'
export const CREATE_FORM_PART_SUCCESS = 'CREATE_FORM_PART_SUCCESS'
export const CREATE_FORM_PART_RESET = 'CREATE_FORM_PART_RESET'
export const CREATE_FORM_PART_FAIL = 'CREATE_FORM_PART_FAIL'

export const UPDATE_FORM_PART_REQUEST = 'UPDATE_FORM_PART_REQUEST'
export const UPDATE_FORM_PART_SUCCESS = 'UPDATE_FORM_PART_SUCCESS'
export const UPDATE_FORM_PART_RESET = 'UPDATE_FORM_PART_RESET'
export const UPDATE_FORM_PART_FAIL = 'UPDATE_FORM_PART_FAIL'

export const DELETE_FORM_PART_REQUEST = 'DELETE_FORM_PART_REQUEST'
export const DELETE_FORM_PART_SUCCESS = 'DELETE_FORM_PART_SUCCESS'
export const DELETE_FORM_PART_RESET = 'DELETE_FORM_PART_RESET'
export const DELETE_FORM_PART_FAIL = 'DELETE_FORM_PART_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
