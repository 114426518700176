import { useQuery } from '@tanstack/react-query'
import axios from '../../configs/APIConfig/axiosInstance'

const fetchServicesTitles = () => {
  return axios.get('public/services/titles')
}

export const useGetServicesTitlesData = () => {
  return useQuery({
    queryKey: 'services_titles',
    queryFn: fetchServicesTitles,
    initialData: { data: { service_titles: [] } },
    select: ({ data }) => {
      return data.service_titles.map(({ title, slug }) => ({
        label: title,
        value: slug,
      }))
    },
  })
}
