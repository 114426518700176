const countries = [
  {
    key: 1,
    fullname: 'Italy',
    name: 'Italy',
    arname: 'ايطاليا',
    apprev: 'IT',
    code: 'it',
    dir: 'ltr',
    image: '/imgs/flags/italy.webp',
  },
  {
    key: 2,
    fullname: 'Spain',
    name: 'Spain',
    arname: 'اسبانيا',
    apprev: 'ES',
    code: 'es',
    dir: 'ltr',
    image: '/imgs/flags/spain.webp',
  },
  {
    key: 3,
    fullname: 'Egypt',
    name: 'EGY',
    arname: 'مصر',
    apprev: 'EG',
    code: 'ar',
    dir: 'rtl',
    image: '/imgs/flags/egypt.webp',
  },
]

export default countries