import { Row, Col, Tooltip } from 'antd'
import { ClearOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import SearchInput from './form/SearchInput'
import SelectDropdown from './form/Select'
import RadioGroup from './form/RadioGroup'
import RangePicker from './form/RangePicker'

const Filters = ({ inputs, hideClearFilters }) => {
  const history = useHistory()

  // Function to render input based on type
  const renderInput = (input) => {
    switch (input.type) {
      case 'SEARCH':
        return <SearchInput {...input} />
      case 'SELECT':
        return <SelectDropdown isFilter={true} {...input} />
      case 'RADIO':
        return <RadioGroup isFilter={true} {...input} />
      case 'RANGE_PICKER':
        return <RangePicker />
      // Add cases for other types as needed
      default:
        return null
    }
  }

  const resetQueryParams = () => {
    history.push({
      pathname: history.location.pathname,
    })
  }

  return (
    <div className='d-flex align-items-start gap-3'>
      <Row gutter={[24, 24]} className='w-100' id='main-form-style'>
        {inputs.map((input, index) => (
          <Col key={index} span={input.span}>
            {renderInput(input)}
          </Col>
        ))}
      </Row>
      <Tooltip title='Clear filters'>
        <ClearOutlined
          className={`cursor-pointer py-3 ${hideClearFilters ? 'd-none' : ''}`}
          onClick={resetQueryParams}
        />
      </Tooltip>
    </div>
  )
}

export default Filters
