export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_RESET = 'LOGIN_RESET'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const PROFILE_DATA_REQUEST = 'PROFILE_DATA_REQUEST'
export const PROFILE_DATA_SUCCESS = 'PROFILE_DATA_SUCCESS'
export const PROFILE_DATA_FAIL = 'PROFILE_DATA_FAIL'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'