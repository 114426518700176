export const ALL_FORMS_REQUEST = 'ALL_FORMS_REQUEST'
export const ALL_FORMS_SUCCESS = 'ALL_FORMS_SUCCESS'
export const ALL_FORMS_FAIL = 'ALL_FORMS_FAIL'

export const FORM_DETAILS_REQUEST = 'FORM_DETAILS_REQUEST'
export const FORM_DETAILS_SUCCESS = 'FORM_DETAILS_SUCCESS'
export const FORM_DETAILS_FAIL = 'FORM_DETAILS_FAIL'

export const CREATE_FORM_REQUEST = 'CREATE_FORM_REQUEST'
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS'
export const CREATE_FORM_RESET = 'CREATE_FORM_RESET'
export const CREATE_FORM_FAIL = 'CREATE_FORM_FAIL'

export const UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST'
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS'
export const UPDATE_FORM_RESET = 'UPDATE_FORM_RESET'
export const UPDATE_FORM_FAIL = 'UPDATE_FORM_FAIL'

export const DELETE_FORM_REQUEST = 'DELETE_FORM_REQUEST'
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS'
export const DELETE_FORM_RESET = 'DELETE_FORM_RESET'
export const DELETE_FORM_FAIL = 'DELETE_FORM_FAIL'

export const SUBMIT_FORM_REQUEST = 'SUBMIT_FORM_REQUEST'
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS'
export const SUBMIT_FORM_RESET = 'SUBMIT_FORM_RESET'
export const SUBMIT_FORM_FAIL = 'SUBMIT_FORM_FAIL'

export const DRAFT_FORM_REQUEST = 'DRAFT_FORM_REQUEST'
export const DRAFT_FORM_SUCCESS = 'DRAFT_FORM_SUCCESS'
export const DRAFT_FORM_RESET = 'DRAFT_FORM_RESET'
export const DRAFT_FORM_FAIL = 'DRAFT_FORM_FAIL'

export const RESET_FORM_REQUEST = 'RESET_FORM_REQUEST'
export const RESET_FORM_SUCCESS = 'RESET_FORM_SUCCESS'
export const RESET_FORM_RESET = 'RESET_FORM_RESET'
export const RESET_FORM_FAIL = 'RESET_FORM_FAIL'

export const DELETE_DRAFT_FILE_REQUEST = 'DELETE_DRAFT_FILE_REQUEST'
export const DELETE_DRAFT_FILE_SUCCESS = 'DELETE_DRAFT_FILE_SUCCESS'
export const DELETE_DRAFT_FILE_RESET = 'DELETE_DRAFT_FILE_RESET'
export const DELETE_DRAFT_FILE_FAIL = 'DELETE_DRAFT_FILE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'