import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, Radio, Button, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { clearModal } from '../../../../../redux/actions/Modal'
import { useExportRequestsReport } from '../../apis/useExportRequestsReport'

import UsersDropdown from '../../../../../components/shared-components/dropdown/usersDropdown'
import ResellersDropdown from '../../../../../components/shared-components/dropdown/resellersDropdown'
import { ReactComponent as Calendar } from '../../../../../components/shared-components/svgs/form/calendar.svg'

const { RangePicker } = DatePicker
const dateFormat = 'MM/DD/YYYY'

export default function ReportDownload() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [userType, setUserType] = useState('resellers')
  const [export_as, setExport_as] = useState('pdf')
  const [from_date, setFrom_date] = useState(null)
  const [to_date, setTo_date] = useState(null)
  const [target_id, setTarget_id] = useState(null)
  const [groupby, setGroupby] = useState(null)

  const onTargetIdSelect = (id) => {
    setTarget_id(id)
  }

  const { mutate: exportRequestsReport, isPending } = useExportRequestsReport()

  return (
    <>
      <h3>{t('Download Report')}</h3>
      <div className='table-filters m-0'>
        {/* USER TYPE */}
        <div className='bg-gray-500 radius-8 px-3 pt-3 mb-3'>
          <label htmlFor='userType mb-0'>{t('User Type')}*</label>
          <Radio.Group
            className='d-flex'
            id='userType'
            name='userType'
            onChange={(e) => {
              setUserType(e.target.value)
              setTarget_id(null)
            }}
            value={userType}
          >
            <Col span={12}>
              <Radio value='users'>Online</Radio>
            </Col>
            <Col span={12}>
              <Radio value='resellers'>Partner</Radio>
            </Col>
          </Radio.Group>
        </div>

        {/* EXPORT AS */}
        <div className='bg-gray-500 radius-8 px-3 pt-3 mb-3'>
          <label htmlFor='export mb-0'>{t('Export As')}*</label>
          <Radio.Group
            className='d-flex'
            id='export'
            name='export'
            onChange={(e) => setExport_as(e.target.value)}
            value={export_as}
          >
            <Col span={12}>
              <Radio value='pdf'>PDF</Radio>
            </Col>
            <Col span={12}>
              <Radio value='excel'>Excel</Radio>
            </Col>
          </Radio.Group>
        </div>

        {/* RANGE PICKER */}
        <div className='input mb-3'>
          <label htmlFor='date'>{t('table.filter.date')}</label>
          <RangePicker
            id='date'
            name='date'
            placeholder={[t('Start date'), t('End date')]}
            format={dateFormat}
            suffixIcon={<Calendar />}
            onCalendarChange={(range, rangeString) => {
              if (rangeString[0]) {
                const dateArr = rangeString[0].split('/')
                setFrom_date(`${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`)
              }
              if (rangeString[1]) {
                const dateArr = rangeString[1].split('/')
                setTo_date(`${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`)
              }
            }}
          />
        </div>

        {/* SEARCH USER */}
        {userType === 'users' && (
          <div className='mb-4'>
            <label htmlFor='target_id'>{t('Select User')}</label>
            <UsersDropdown onSelect={onTargetIdSelect} />
          </div>
        )}

        {/* SEARCH RESELLER */}
        {userType === 'resellers' && (
          <div className='mb-4'>
            <label htmlFor='target_id'>{t('Select Reseller')}</label>
            <ResellersDropdown onSelect={onTargetIdSelect} />
          </div>
        )}

        {/* GROUP BY */}
        <div className='bg-gray-500 radius-8 px-3 pt-3 mb-3'>
          <label htmlFor='group mb-0'>{t('Group By')}</label>
          <Radio.Group
            className='d-flex'
            id='group'
            name='group'
            onChange={(e) => setGroupby(e.target.value)}
            value={groupby}
          >
            <Col span={12}>
              <Radio value='service'>Service</Radio>
            </Col>
            <Col span={12}>
              <Radio value='status'>Status</Radio>
            </Col>
          </Radio.Group>
        </div>
      </div>

      <div className='buttons mt-3'>
        <Button onClick={() => dispatch(clearModal())}>
          {t('form.btn.cancel')}
        </Button>
        <Button
          loading={isPending}
          className='submit success'
          onClick={() => {
            exportRequestsReport({
              userType,
              export_as,
              from_date,
              to_date,
              target_id,
              groupby,
            })
          }}
        >
          {t('Download')}
        </Button>
      </div>
    </>
  )
}
