import { DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import './RangePicker.scss'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'

const RangePickerC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)

  return (
    <RangePicker
      id='date'
      name='date'
      format={dateFormat}
      placeholder={[t('Start date'), t('End date')]}
      onCalendarChange={(_, rangeString) => {
        if (rangeString[0] && rangeString[1]) {
          const from_date = rangeString[0].split('/').join('-')
          const to_date = rangeString[1].split('/').join('-')

          searchParams.set('from_date', from_date)
          searchParams.set('to_date', to_date)
          searchParams.set('page', 1)

          history.push({
            pathname: history.location.pathname,
            search: searchParams.toString(),
          })
        }
      }}
    />
  )
}

export default RangePickerC
