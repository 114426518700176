import { useEffect, useState } from 'react'
import { Row, Col, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { clearModal } from '../../../../redux/actions/Modal'
import { useCreateServiceFQA } from '../apis/useCreateServiceFQA'
import { useUpdateServiceFQA } from '../apis/useUpdateServiceFQA'
import { useGetServicesTitlesData } from '../../../hooks/useGetServicesTitlesData'

import InputNormal from '../../../components/form/Input'
import TextAreaInput from '../../../components/form/TextArea'
import SelectDropdown from '../../../components/form/Select'

const initialValues = {
  service_slug: '',
  question: '',
  answer: '',
}

const CreateEditQuestion = ({ selectedElement }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    if (selectedElement)
      setFormValues({
        service_slug: selectedElement.service_slug,
        question: selectedElement.question,
        answer: selectedElement.answer,
      })
  }, [selectedElement])

  const { data: servicesTitles } = useGetServicesTitlesData()

  const onCancel = () => {
    dispatch(clearModal())
  }

  const { mutate: createService } = useCreateServiceFQA()
  const onCreate = () => {
    setIsLoading(true)
    createService(formValues, {
      onSettled: () => setIsLoading(false),
      onSuccess: () => {
        setFormValues(initialValues)
      },
    })
  }

  const { mutate: updateService } = useUpdateServiceFQA()
  const onUpdate = () => {
    setIsLoading(true)
    updateService(
      { id: selectedElement.id, formValues },
      {
        onSettled: () => setIsLoading(false),
        onSuccess: () => {
          setFormValues(initialValues)
        },
      }
    )
  }

  return (
    <div id='main-form-style'>
      <Row justify='center' gutter={[24, 16]}>
        <Col span={24}>
          <SelectDropdown
            label={t('Select a Service')}
            placeholder={t('Select a Service')}
            name='service_slug'
            options={servicesTitles}
            value={formValues.service_slug}
            setValue={(value) =>
              setFormValues({
                ...formValues,
                service_slug: value,
              })
            }
          />
        </Col>
        <Col span={24}>
          <InputNormal
            label={t('Question')}
            name='question'
            value={formValues.question}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                question: e.target.value,
              })
            }
          />
        </Col>
        <Col span={24}>
          <TextAreaInput
            label={t('Answer')}
            name='answer'
            value={formValues.answer}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                answer: e.target.value,
              })
            }
          />
        </Col>
      </Row>

      <div className='d-flex justify-content-end gap-3 mt-4'>
        <Button className='fs-14 fw-600' type='text' onClick={onCancel}>
          {t('form.btn.cancel')}
        </Button>
        <Button
          className='btn-green fs-14 fw-600 px-4 py-2 rounded'
          type='primary'
          loading={isLoading}
          onClick={selectedElement ? onUpdate : onCreate}
        >
          {selectedElement ? t('Update') : t('Create')}
        </Button>
      </div>
    </div>
  )
}

export default CreateEditQuestion
