import {
  ALL_CLIENTS_REQUEST,
  ALL_CLIENTS_SUCCESS,
  ALL_CLIENTS_FAIL,
  GET_CLIENT_STATUS_REQUEST,
  GET_CLIENT_STATUS_SUCCESS,
  GET_CLIENT_STATUS_FAIL,
  GET_CLIENT_REQUESTS_REQUEST,
  GET_CLIENT_REQUESTS_SUCCESS,
  GET_CLIENT_REQUESTS_FAIL,
  APPROVE_CLIENT_REQUEST,
  APPROVE_CLIENT_SUCCESS,
  APPROVE_CLIENT_RESET,
  APPROVE_CLIENT_FAIL,
  REJECT_CLIENT_REQUEST,
  REJECT_CLIENT_SUCCESS,
  REJECT_CLIENT_RESET,
  REJECT_CLIENT_FAIL,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_RESET,
  UPDATE_CLIENT_FAIL,
  CLEAR_ERRORS
} from '../constants/Clients'

export const allClientsReducer = (state = { profiles: [] }, action) => {
  switch (action.type) {

    case ALL_CLIENTS_REQUEST:
      return {
        loading: true
      }

    case ALL_CLIENTS_SUCCESS:
      return {
        loading: false,
        profiles: action.payload.profiles.data,
        currentPage: action.payload.profiles.meta.current_page,
        totalPages: action.payload.profiles.meta.last_page,
      }

    case ALL_CLIENTS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const clientRequestsReducer = (state = { requests: [] }, action) => {
  switch (action.type) {
    case GET_CLIENT_REQUESTS_REQUEST:
      return {
        loading: true,
      }

    case GET_CLIENT_REQUESTS_SUCCESS:
      return {
        loading: false,
        requests: action.payload.requests.data,
        currentPage: action.payload.requests.meta.current_page,
        totalPages: action.payload.requests.meta.last_page,
      }

    case GET_CLIENT_REQUESTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const clientStatusReducer = (state = { message: '' }, action) => {
  switch (action.type) {
    case GET_CLIENT_STATUS_REQUEST:
      return {
        loading: true,
      }

    case GET_CLIENT_STATUS_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case GET_CLIENT_STATUS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const clientReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_CLIENT_REQUEST:
    case APPROVE_CLIENT_REQUEST:
    case REJECT_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_CLIENT_SUCCESS:
    case APPROVE_CLIENT_SUCCESS:
    case REJECT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message
      }

    case UPDATE_CLIENT_RESET:
    case APPROVE_CLIENT_RESET:
    case REJECT_CLIENT_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null
      }

    case UPDATE_CLIENT_FAIL:
    case APPROVE_CLIENT_FAIL:
    case REJECT_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}