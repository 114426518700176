import { useMutation } from '@tanstack/react-query'
import axios from '../../../../configs/APIConfig/axiosInstance'
import { useDispatch } from 'react-redux'
import { clearModal } from '../../../../redux/actions/Modal'
import { openNotification, errorHandling } from '../../../../utils/utils'

export const useExportRequestsReport = () => {
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (formValues) => {
      const { userType, export_as, from_date, to_date, target_id, groupby } =
        formValues

      const response = await axios.get(
        `/admin/reports/service_requests?target=${userType}&export_as=${export_as}${
          from_date ? `&from_date=${from_date}` : ''
        }${to_date ? `&to_date=${to_date}` : ''}${
          target_id ? `&target_id=${target_id}` : ''
        }${groupby ? `&groupby=${groupby}` : ''}`,
        { responseType: 'blob' }
      )
      return response.data
    },
    onSuccess: (data) => {
      const url = URL.createObjectURL(data)
      window.open(url)
      dispatch(clearModal())
    },
    onError: (error) => {
      const { message } = errorHandling(error)
      openNotification('error', message)
    },
  })
}
