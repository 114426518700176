import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import './SearchInput.scss'

const SearchInput = ({ label, placeholder, name }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)

  const handleSearch = (e) => {
    const value = e.target.value
    searchParams.set(name || 'search', value)
    searchParams.set('page', 1)
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <div className='search-input'>
      {label && <label htmlFor={name}>{label}</label>}
      <Input
        id={name}
        name={name}
        placeholder={placeholder || `${t('nav.search.ph')}...`}
        suffix={<SearchOutlined />}
        onPressEnter={handleSearch}
      />
    </div>
  )
}

export default SearchInput
