import {
  ALL_CONTACTS_REQUEST,
  ALL_CONTACTS_SUCCESS,
  ALL_CONTACTS_FAIL,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_FAIL,
  REPLY_CONTACT_REQUEST,
  REPLY_CONTACT_SUCCESS,
  REPLY_CONTACT_RESET,
  REPLY_CONTACT_FAIL,
  CLOSE_CONTACT_REQUEST,
  CLOSE_CONTACT_SUCCESS,
  CLOSE_CONTACT_RESET,
  CLOSE_CONTACT_FAIL,
  CLEAR_ERRORS
} from '../constants/ContactUs'

export const allContactUsReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case ALL_CONTACTS_REQUEST:
      return {
        loading: true,
      }

    case ALL_CONTACTS_SUCCESS:
      return {
        loading: false,
        items: action.payload.items.data,
        stat: action.payload.stat,
        currentPage: action.payload.items.meta.current_page,
        totalPages: action.payload.items.meta.last_page,
        totalItems: action.payload.items.meta.total,
      }

    case ALL_CONTACTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const contactDetailsReducer = (state = { messages: [] }, action) => {
  switch (action.type) {
    case CONTACT_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case CONTACT_DETAILS_SUCCESS:
      return {
        loading: false,
        messages: action.payload.messages,
      }

    case CONTACT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const replyContactUsReducer = (state = {}, action) => {
  switch (action.type) {

    case REPLY_CONTACT_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case REPLY_CONTACT_SUCCESS:
      return {
        loading: false,
        message: action.payload.message
      }

    case REPLY_CONTACT_RESET:
      return {
        loading: false,
        message: null
      }
    
    case REPLY_CONTACT_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const contactUsReducer = (state = {}, action) => {
  switch (action.type) {

    case CLOSE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true
      }

    case CLOSE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message
      }

    case CLOSE_CONTACT_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null
      }

    case CLOSE_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}