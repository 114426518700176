import { SET_MODAL, CLEAR_MODAL, SET_MODAL_TYPE } from '../constants/Modal'

const initialState = {
  modalType: 'DEFAULT',
  isOpen: false,
  content: null,
  title: '',
  width: '600'
}

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        isOpen: !!action.payload.content,
        content: action.payload.content,
        title: action.payload.title,
        description: action.payload.description,
        width: action.payload.width,
      }
    case CLEAR_MODAL:
      return {
        ...state,
        isOpen: false,
        content: null,
        title: '',
      }
    case SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload.modalType,
      }
    default:
      return state
  }
}
