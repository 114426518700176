import {
  ASSIGN_PACKAGES_REQUEST,
  ASSIGN_PACKAGES_SUCCESS,
  ASSIGN_PACKAGES_RESET,
  ASSIGN_PACKAGES_FAIL,
  CLEAR_ERRORS,
} from '../constants/Packages'

// ASSIGN PACKAGE
export const assignPackageReducer = (state = { package: {} }, action) => {
  switch (action.type) {
    case ASSIGN_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ASSIGN_PACKAGES_SUCCESS:
      return {
        loading: false,
        package: action.payload.package,
        isAssigned: true,
      }

    case ASSIGN_PACKAGES_RESET:
      return {
        loading: false,
        isAssigned: false,
      }

    case ASSIGN_PACKAGES_FAIL:
      return {
        loading: false,
        error: action.payload,
        isAssigned: false,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
