import {
  CREATE_QUESTION_FIELD_REQUEST,
  CREATE_QUESTION_FIELD_SUCCESS,
  CREATE_QUESTION_FIELD_RESET,
  CREATE_QUESTION_FIELD_FAIL,
  UPDATE_QUESTION_FIELD_REQUEST,
  UPDATE_QUESTION_FIELD_SUCCESS,
  UPDATE_QUESTION_FIELD_RESET,
  UPDATE_QUESTION_FIELD_FAIL,
  DELETE_QUESTION_FIELD_REQUEST,
  DELETE_QUESTION_FIELD_SUCCESS,
  DELETE_QUESTION_FIELD_RESET,
  DELETE_QUESTION_FIELD_FAIL,
  CLEAR_ERRORS,
} from '../constants/QuestionField'

export const createQuestionFieldReducer = (state = { field: {} }, action) => {
  switch (action.type) {
    case CREATE_QUESTION_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_QUESTION_FIELD_SUCCESS:
      return {
        loading: false,
        field: action.payload.field,
      }

    case CREATE_QUESTION_FIELD_RESET:
      return {
        loading: false,
        field: null,
        message: null,
      }

    case CREATE_QUESTION_FIELD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const questionFieldReducer = (state = { field: {} }, action) => {
  switch (action.type) {
    case UPDATE_QUESTION_FIELD_REQUEST:
    case DELETE_QUESTION_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_QUESTION_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        field: action.payload.field,
      }

    case UPDATE_QUESTION_FIELD_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      }

    case DELETE_QUESTION_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_QUESTION_FIELD_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_QUESTION_FIELD_FAIL:
    case DELETE_QUESTION_FIELD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}