import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_RESET,
  LOGIN_FAIL,
  PROFILE_DATA_REQUEST,
  PROFILE_DATA_SUCCESS,
  PROFILE_DATA_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_FAIL,
  CLEAR_ERRORS,
} from '../constants/Auth'

// LOGIN REDUCER
export const userAuthReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        userData: action.payload,
        message: action.payload.message,
        user: action.payload.profile,
        token: action.payload.token,
      }

    case LOGIN_RESET:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      }

    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const myProfileReducer = (
  state = { profile: {}, hasFullPermissions: false, permissions: [] },
  action
) => {
  switch (action.type) {
    case PROFILE_DATA_REQUEST:
      return {
        loading: true,
      }

    case PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        profile: action.payload.data.profile,
        hasFullPermissions:
          action.payload.data.profile.role?.has_full_permissions,
        permissions: action.payload.data.profile.role?.permissions,
      }

    case PROFILE_DATA_FAIL:
      return {
        loading: false,
        error: action.payload,
        isAuthenticated: false,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const UpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_PROFILE_RESET:
      return {
        loading: false,
      }

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        loading: true,
      }

    case UPDATE_PASSWORD_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case UPDATE_PASSWORD_RESET:
      return {
        loading: false,
      }

    case UPDATE_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
