import { Drawer, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/actions/Modal'
import { useGetServiceFQAData } from '../apis/useGetServiceFQAData'
import { useGetServicesTitlesData } from '../../../hooks/useGetServicesTitlesData'

import Loading from '../../../../components/shared-components/Loading'
import Filters from '../../../components/Filters'
import Faqs from '../../../components/Faqs'
import Pagination from '../../../components/Pagination'
import CreateEditQuestion from './CreateEditQuestion'

const FQADrawer = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  const searchParams = new URLSearchParams(location.search)
  const page = searchParams.get('FQA_page') || 1
  const search = searchParams.get('FQA_search')
  const service_slug = searchParams.get('FQA_service_slug')

  const { data: servicesTitles } = useGetServicesTitlesData()
  const { data: FQASData, isLoading } = useGetServiceFQAData({
    search,
    service_slug,
  })

  return (
    <Drawer
      title={
        <div className='d-flex align-items-center justify-content-between'>
          <h3 className='fs-5 fw-semibold'>
            {t('Frequently Questioned Answers')}
          </h3>
          <Button
            className='btn-green fs-14 fw-bold rounded-1 px-5 py-12'
            onClick={() =>
              dispatch(
                setModal({
                  title: 'Create Question Answer',
                  content: <CreateEditQuestion />,
                })
              )
            }
          >
            {t('New Question')}
          </Button>
        </div>
      }
      placement='right'
      size='large'
      onClose={() => setOpen(false)}
      open={open}
    >
      <div className='mb-4'>
        <Filters
          inputs={[
            { type: 'SEARCH', span: 12, name: 'FQA_search' },
            {
              type: 'SELECT',
              span: 12,
              name: 'FQA_service_slug',
              placeholder: t('table.filter.ph.service'),
              options: servicesTitles,
              value: service_slug,
            },
          ]}
          hideClearFilters
        />
      </div>
      {isLoading ? <Loading /> : <Faqs data={FQASData?.data} />}
      <Pagination
        current={page * 1}
        total={FQASData?.total}
        customName='FQA_page'
      />
    </Drawer>
  )
}

export default FQADrawer
