export const CREATE_FORM_QUESTION_REQUEST = 'CREATE_FORM_QUESTION_REQUEST'
export const CREATE_FORM_QUESTION_SUCCESS = 'CREATE_FORM_QUESTION_SUCCESS'
export const CREATE_FORM_QUESTION_RESET = 'CREATE_FORM_QUESTION_RESET'
export const CREATE_FORM_QUESTION_FAIL = 'CREATE_FORM_QUESTION_FAIL'

export const UPDATE_FORM_QUESTION_REQUEST = 'UPDATE_FORM_QUESTION_REQUEST'
export const UPDATE_FORM_QUESTION_SUCCESS = 'UPDATE_FORM_QUESTION_SUCCESS'
export const UPDATE_FORM_QUESTION_RESET = 'UPDATE_FORM_QUESTION_RESET'
export const UPDATE_FORM_QUESTION_FAIL = 'UPDATE_FORM_QUESTION_FAIL'

export const DELETE_FORM_QUESTION_REQUEST = 'DELETE_FORM_QUESTION_REQUEST'
export const DELETE_FORM_QUESTION_SUCCESS = 'DELETE_FORM_QUESTION_SUCCESS'
export const DELETE_FORM_QUESTION_RESET = 'DELETE_FORM_QUESTION_RESET'
export const DELETE_FORM_QUESTION_FAIL = 'DELETE_FORM_QUESTION_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
