import {
  ALL_PAYMENTS_REQUEST,
  ALL_PAYMENTS_SUCCESS,
  ALL_PAYMENTS_FAIL,
  RESELLER_INVOICES_REQUEST,
  RESELLER_INVOICES_SUCCESS,
  RESELLER_INVOICES_FAIL,
  UPLOAD_INVOICE_REQUEST,
  UPLOAD_INVOICE_SUCCESS,
  UPLOAD_INVOICE_RESET,
  UPLOAD_INVOICE_FAIL,
  CLEAR_ERRORS,
} from '../constants/Payments'

export const allPaymentsReducer = (state = { payments: [] }, action) => {
  switch (action.type) {
    case ALL_PAYMENTS_REQUEST:
      return {
        loading: true,
      }

    case ALL_PAYMENTS_SUCCESS:
      return {
        loading: false,
        payments: action.payload.items.data,
        currentPage: action.payload.items.meta.current_page,
        totalPages: action.payload.items.meta.last_page,
        totalItems: action.payload.items.meta.total,
      }

    case ALL_PAYMENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const resellerInvoicesReducer = (state = { payments: [] }, action) => {
  switch (action.type) {
    case RESELLER_INVOICES_REQUEST:
      return {
        loading: true,
      }

    case RESELLER_INVOICES_SUCCESS:
      return {
        loading: false,
        payments: action.payload.invoices.data,
        currentPage: action.payload.invoices.meta.current_page,
        totalPages: action.payload.invoices.meta.last_page,
        totalItems: action.payload.invoices.meta.total,
      }

    case RESELLER_INVOICES_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export const uploadInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_INVOICE_REQUEST:
      return {
        loading: true,
      }

    case UPLOAD_INVOICE_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      }

    case UPLOAD_INVOICE_RESET:
      return {
        loading: false,
        message: null,
      }

    case UPLOAD_INVOICE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
