import React, { Suspense } from 'react'
import i18next from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Provider } from 'react-redux'
import store from './redux/store'

import Loading from './components/shared-components/Loading'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import ReactDOM from 'react-dom/client'
const root = ReactDOM.createRoot(document.getElementById('root'))

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: 'it',
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: `/assets/locales/{{lng}}/translation.json`,
    },
    react: { useSuspense: true },
  })

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <I18nextProvider>
          <App />
        </I18nextProvider>
      </Suspense>
    </Provider>
  </React.StrictMode>
)
