import MenuContent from './MenuContent'
import { ReactComponent as Close } from '../shared-components/svgs/close.svg'

export default function Sidenav({ navOpen, setNavOpen }) {
  return (
    <>
      <div className='logo'>
        <img src='/imgs/logo-dark.svg' alt='Logo' />
      </div>
      <MenuContent {...{ navOpen, setNavOpen }} />
      <Close className='close-icon' onClick={() => setNavOpen(!navOpen)} />
    </>
  )
}