import {
  ALL_PERMISSIONS_REQUEST,
  ALL_PERMISSIONS_SUCCESS,
  ALL_PERMISSIONS_FAIL,
  ALL_ROLES_REQUEST,
  ALL_ROLES_SUCCESS,
  ALL_ROLES_FAIL,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_RESET,
  CREATE_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_RESET,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_RESET,
  DELETE_ROLE_FAIL,
  CLEAR_ERRORS
} from '../constants/Roles-Permissions'

// PERMISSIONS
export const allPermissionsReducer = (state = { permissions: [] }, action) => {
  switch (action.type) {

    case ALL_PERMISSIONS_REQUEST:
      return {
        loading: true
      }

    case ALL_PERMISSIONS_SUCCESS:
      return {
        loading: false,
        permissions: action.payload.permissions.data,
        currentPage: action.payload.permissions.meta.current_page,
        totalPages: action.payload.permissions.meta.last_page,
      }

    case ALL_PERMISSIONS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

// ROLES
export const createRoleReducer = (state = { role: {} }, action) => {
  switch (action.type) {

    case CREATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_ROLE_SUCCESS:
      return {
        loading: false,
        role: action.payload.role,
        message: action.payload.message
      }

    case CREATE_ROLE_RESET:
      return {
        loading: false,
        role: null,
        message: null
      }
    
    case CREATE_ROLE_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}

export const allRolesReducer = (state = { roles: [] }, action) => {
  switch (action.type) {

    case ALL_ROLES_REQUEST:
      return {
        loading: true
      }

    case ALL_ROLES_SUCCESS:
      return {
        loading: false,
        roles: action.payload.roles,
        hasPermissionTo: action.payload.hasPermissionTo,
        availablePermissions: action.payload.availablePermissions
      }

    case ALL_ROLES_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const roleReducer = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_ROLE_REQUEST:
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_ROLE_RESET:
      return {
        loading: false,
        isUpdated: false,
      }

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_ROLE_RESET:
      return {
        loading: false,
        isDeleted: false,
        message: null
      }

    case UPDATE_ROLE_FAIL:
    case DELETE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}