import {
  BLOG_CATEGORIES_REQUEST,
  BLOG_CATEGORIES_SUCCESS,
  BLOG_CATEGORIES_FAIL,
  LANGS_CATEGORY_DETAILS_REQUEST,
  LANGS_CATEGORY_DETAILS_SUCCESS,
  LANGS_CATEGORY_DETAILS_FAIL,
  CREATE_BLOG_CATEGORY_REQUEST,
  CREATE_BLOG_CATEGORY_SUCCESS,
  CREATE_BLOG_CATEGORY_RESET,
  CREATE_BLOG_CATEGORY_FAIL,
  UPDATE_BLOG_CATEGORY_REQUEST,
  UPDATE_BLOG_CATEGORY_SUCCESS,
  UPDATE_BLOG_CATEGORY_RESET,
  UPDATE_BLOG_CATEGORY_FAIL,
  DELETE_BLOG_CATEGORY_REQUEST,
  DELETE_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY_RESET,
  DELETE_BLOG_CATEGORY_FAIL,
  ALL_BLOGS_REQUEST,
  ALL_BLOGS_SUCCESS,
  ALL_BLOGS_FAIL,
  NEW_BLOGS_REQUEST,
  NEW_BLOGS_SUCCESS,
  NEW_BLOGS_FAIL,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_DETAILS_FAIL,
  LANGS_BLOG_DETAILS_REQUEST,
  LANGS_BLOG_DETAILS_SUCCESS,
  LANGS_BLOG_DETAILS_FAIL,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_RESET,
  CREATE_BLOG_FAIL,
  UPDATE_BLOG_REQUEST,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_RESET,
  UPDATE_BLOG_FAIL,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_RESET,
  DELETE_BLOG_FAIL,
  BLOG_INDEX_DETAILS_REQUEST,
  BLOG_INDEX_DETAILS_SUCCESS,
  BLOG_INDEX_DETAILS_FAIL,
  CREATE_BLOG_INDEX_REQUEST,
  CREATE_BLOG_INDEX_SUCCESS,
  CREATE_BLOG_INDEX_RESET,
  CREATE_BLOG_INDEX_FAIL,
  UPDATE_BLOG_INDEX_REQUEST,
  UPDATE_BLOG_INDEX_SUCCESS,
  UPDATE_BLOG_INDEX_RESET,
  UPDATE_BLOG_INDEX_FAIL,
  DELETE_BLOG_INDEX_REQUEST,
  DELETE_BLOG_INDEX_SUCCESS,
  DELETE_BLOG_INDEX_RESET,
  DELETE_BLOG_INDEX_FAIL,
  BLOG_PARAGRAPH_DETAILS_REQUEST,
  BLOG_PARAGRAPH_DETAILS_SUCCESS,
  BLOG_PARAGRAPH_DETAILS_FAIL,
  CREATE_BLOG_PARAGRAPH_REQUEST,
  CREATE_BLOG_PARAGRAPH_SUCCESS,
  CREATE_BLOG_PARAGRAPH_RESET,
  CREATE_BLOG_PARAGRAPH_FAIL,
  UPDATE_BLOG_PARAGRAPH_REQUEST,
  UPDATE_BLOG_PARAGRAPH_SUCCESS,
  UPDATE_BLOG_PARAGRAPH_RESET,
  UPDATE_BLOG_PARAGRAPH_FAIL,
  DELETE_BLOG_PARAGRAPH_REQUEST,
  DELETE_BLOG_PARAGRAPH_SUCCESS,
  DELETE_BLOG_PARAGRAPH_RESET,
  DELETE_BLOG_PARAGRAPH_FAIL,
  CLEAR_ERRORS,
} from '../constants/Blog'

// ALL BLOG CATEGORIES
export const BlogCategoriesReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case BLOG_CATEGORIES_REQUEST:
      return {
        loading: true,
      }

    case BLOG_CATEGORIES_SUCCESS:
      return {
        loading: false,
        categories: action.payload.blog_categories,
      }

    case BLOG_CATEGORIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// BLOG CATEGORY DETAILS (FOR EDIT)
export const categoryBlogDetailsReducer = (
  state = { category: {} },
  action
) => {
  switch (action.type) {
    case LANGS_CATEGORY_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case LANGS_CATEGORY_DETAILS_SUCCESS:
      return {
        loading: false,
        titles: action.payload.blog_category.title,
      }

    case LANGS_CATEGORY_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// CREATE BLOG CATEGORY
export const createBlogCategoryReducer = (state = { category: {} }, action) => {
  switch (action.type) {
    case CREATE_BLOG_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_BLOG_CATEGORY_SUCCESS:
      return {
        loading: false,
        category: action.payload.blog_category,
        message: action.payload.message,
      }

    case CREATE_BLOG_CATEGORY_RESET:
      return {
        loading: false,
        category: null,
        message: null,
      }

    case CREATE_BLOG_CATEGORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// UPDATE & DELETE CATEGORY
export const blogCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BLOG_CATEGORY_REQUEST:
    case DELETE_BLOG_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_BLOG_CATEGORY_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case DELETE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_BLOG_CATEGORY_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_BLOG_CATEGORY_FAIL:
    case DELETE_BLOG_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// ====================================================================================

// ALL BLOGS
export const allBlogsReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case ALL_BLOGS_REQUEST:
      return {
        loading: true,
      }

    case ALL_BLOGS_SUCCESS:
      return {
        loading: false,
        blogs: action.payload.blogs,
      }

    case ALL_BLOGS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// NEW BLOGS
export const newBlogsReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case NEW_BLOGS_REQUEST:
      return {
        loading: true,
      }

    case NEW_BLOGS_SUCCESS:
      return {
        loading: false,
        blogs: action.payload.latest_blogs,
      }

    case NEW_BLOGS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// BLOG DETAILS
export const BlogDetailsPublicReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case BLOG_DETAILS_SUCCESS:
      return {
        loading: false,
        blog: action.payload.blog,
      }

    case BLOG_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// BLOG DETAILS (FOR EDIT)
export const BlogDetailsReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case LANGS_BLOG_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case LANGS_BLOG_DETAILS_SUCCESS:
      return {
        loading: false,
        blog: action.payload.blog,
      }

    case LANGS_BLOG_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// CREATE BLOG CATEGORY
export const createBlogReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case CREATE_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_BLOG_SUCCESS:
      return {
        loading: false,
        blog: action.payload.blog,
        message: action.payload.message,
      }

    case CREATE_BLOG_RESET:
      return {
        loading: false,
        category: null,
        message: null,
      }

    case CREATE_BLOG_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// UPDATE & DELETE CATEGORY
export const blogReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BLOG_REQUEST:
    case DELETE_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_BLOG_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_BLOG_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_BLOG_FAIL:
    case DELETE_BLOG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// ====================================================================================

// BLOG INDEX DETAILS (FOR EDIT)
export const BlogIndexDetailsReducer = (state = { index: {} }, action) => {
  switch (action.type) {
    case BLOG_INDEX_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case BLOG_INDEX_DETAILS_SUCCESS:
      return {
        loading: false,
        index: action.payload.index,
      }

    case BLOG_INDEX_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// CREATE BLOG INDEX
export const createBlogIndexReducer = (state = { index: {} }, action) => {
  switch (action.type) {
    case CREATE_BLOG_INDEX_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_BLOG_INDEX_SUCCESS:
      return {
        loading: false,
        index: action.payload.index,
        message: action.payload.message,
      }

    case CREATE_BLOG_INDEX_RESET:
      return {
        loading: false,
        index: null,
        message: null,
      }

    case CREATE_BLOG_INDEX_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// UPDATE & DELETE INDEX
export const blogIndexReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BLOG_INDEX_REQUEST:
    case DELETE_BLOG_INDEX_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_BLOG_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_BLOG_INDEX_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case DELETE_BLOG_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_BLOG_INDEX_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_BLOG_INDEX_FAIL:
    case DELETE_BLOG_INDEX_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// ====================================================================================

// BLOG PARAGRAPH DETAILS (FOR EDIT)
export const BlogParagraphDetailsReducer = (state = { paragraph: {} }, action) => {
  switch (action.type) {
    case BLOG_PARAGRAPH_DETAILS_REQUEST:
      return {
        loading: true,
      }

    case BLOG_PARAGRAPH_DETAILS_SUCCESS:
      return {
        loading: false,
        paragraph: action.payload.paragraph,
      }

    case BLOG_PARAGRAPH_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// CREATE BLOG PARAGRAPH
export const createBlogParagraphReducer = (state = { paragraph: {} }, action) => {
  switch (action.type) {
    case CREATE_BLOG_PARAGRAPH_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_BLOG_PARAGRAPH_SUCCESS:
      return {
        loading: false,
        paragraph: action.payload.paragraph,
        message: action.payload.message,
      }

    case CREATE_BLOG_PARAGRAPH_RESET:
      return {
        loading: false,
        paragraph: null,
        message: null,
      }

    case CREATE_BLOG_PARAGRAPH_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

// UPDATE & DELETE PARAGRAPH
export const blogParagraphReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BLOG_PARAGRAPH_REQUEST:
    case DELETE_BLOG_PARAGRAPH_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_BLOG_PARAGRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_BLOG_PARAGRAPH_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        message: null,
      }

    case DELETE_BLOG_PARAGRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
        message: action.payload.message,
      }

    case DELETE_BLOG_PARAGRAPH_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
        message: null,
      }

    case UPDATE_BLOG_PARAGRAPH_FAIL:
    case DELETE_BLOG_PARAGRAPH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}