export const ALL_CLIENTS_REQUEST = 'ALL_CLIENTS_REQUEST'
export const ALL_CLIENTS_SUCCESS = 'ALL_CLIENTS_SUCCESS'
export const ALL_CLIENTS_FAIL = 'ALL_CLIENTS_FAIL'

export const GET_CLIENT_STATUS_REQUEST = 'GET_CLIENT_STATUS_REQUEST'
export const GET_CLIENT_STATUS_SUCCESS = 'GET_CLIENT_STATUS_SUCCESS'
export const GET_CLIENT_STATUS_FAIL = 'GET_CLIENT_STATUS_FAIL'

export const GET_CLIENT_REQUESTS_REQUEST = 'GET_CLIENT_REQUESTS_REQUEST'
export const GET_CLIENT_REQUESTS_SUCCESS = 'GET_CLIENT_REQUESTS_SUCCESS'
export const GET_CLIENT_REQUESTS_FAIL = 'GET_CLIENT_REQUESTS_FAIL'

export const APPROVE_CLIENT_REQUEST = 'APPROVE_CLIENT_REQUEST'
export const APPROVE_CLIENT_SUCCESS = 'APPROVE_CLIENT_SUCCESS'
export const APPROVE_CLIENT_RESET = 'APPROVE_CLIENT_RESET'
export const APPROVE_CLIENT_FAIL = 'APPROVE_CLIENT_FAIL'

export const REJECT_CLIENT_REQUEST = 'REJECT_CLIENT_REQUEST'
export const REJECT_CLIENT_SUCCESS = 'REJECT_CLIENT_SUCCESS'
export const REJECT_CLIENT_RESET = 'REJECT_CLIENT_RESET'
export const REJECT_CLIENT_FAIL = 'REJECT_CLIENT_FAIL'

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS'
export const UPDATE_CLIENT_RESET = 'UPDATE_CLIENT_RESET'
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'