import {
  ALL_ADMINS_REQUEST,
  ALL_ADMINS_SUCCESS,
  ALL_ADMINS_FAIL,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_RESET,
  CREATE_ADMIN_FAIL,
  CLEAR_ERRORS
} from '../constants/Admins'

// QUERY ADMINS
export const allAdminsReducer = (state = { profiles: [] }, action) => {
  switch (action.type) {

    case ALL_ADMINS_REQUEST:
      return {
        loading: true
      }

    case ALL_ADMINS_SUCCESS:
      return {
        loading: false,
        profiles: action.payload.profiles.data,
        currentPage: action.payload.profiles.meta.current_page,
        totalPages: action.payload.profiles.meta.last_page,
        totalItems: action.payload.profiles.meta.total,
      }

    case ALL_ADMINS_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state;
  }
}

export const createAdminReducer = (state = { profile: {} }, action) => {
  switch (action.type) {

    case CREATE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case CREATE_ADMIN_SUCCESS:
      return {
        loading: false,
        profile: action.payload.profile,
        message: action.payload.message
      }

    case CREATE_ADMIN_RESET:
      return {
        loading: false,
        profile: null,
        message: null
      }
    
    case CREATE_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case CLEAR_ERRORS:
			return {
				...state,
				error: null
			}

		default:
			return state
  }
}